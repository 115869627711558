@import (reference) "../../theme/variables.less";
@import (reference) "../theme/variables.less";
@import (reference) "../../libs/mixins.less";

@import "libs/bootstrap.3.2.grid.less";
@import (reference) "../../libs/ceaser.custom.less";
@import "../../mixins/animated/bounceOutUp.less";
@import "../modules/user-avatar.less";

.fm-header{

    @media (max-width: 960px) {
      position:absolute;
    }

    position:fixed;
    z-index:9;
    width:100%;
    background-color:@septenary-color-xxxlight;
    font-size:14px;
    .primary-font;
    .drop-shadow(0, 1px, 2px, 0.2);

    .section-inner{
        .easeOutQuad(.1s);
        display:table;
        height:80px;
        padding:20px 0;
    }

    &.is-light{

        .section-inner{
            .easeOutQuad(.3s);
            height:30px;
            padding:5px 0;
        }

        .fm-header-logo{
            width:33px;
        }
    }

    &.is-logged-in {
        .logged-out-actions {
            display: none;
        }
        .logged-in-actions {
            display: block;
        }
    }
}

.logo-and-nav{
    .make-xs-column(10);
}

.fm-header-logo{
    display:inline-block;
    width:171px;
    vertical-align:top;
    padding-top:4px;
    overflow:hidden;
    .easeOutQuad(.3s);

    .fm-logo{
        display:inline-block;
        width:171px;
        height:33px;
        .fm-logo-sprite;
    }
}

.primary-nav{
    display:inline-block;
    vertical-align:top;
    line-height:40px;
    white-space: nowrap;
}

.primary-nav-link{
    margin-left:7%;
    font-weight:700;
    color:@septenary-color-xxdark;
    text-transform:uppercase;
    white-space: nowrap;
    .easeOutQuad(.2s);

    &:hover{
        color:@primary-color;
        text-decoration:none;
    }
}

 /*******************************************/
/* Logged out */

.logged-out-actions{
    .make-xs-column(2);
    margin-top:10px;
    text-align:right;
    font-weight:700;
    color:@septenary-color;

    a{
        color:@septenary-color-xxdark;
    }
}

 /*******************************************/
/* Logged in */

.logged-in-actions{
    display: none;
    .make-xs-column(2);
    margin-top:8px;
    text-align:right;
}

.user-menu-bt{
    position:relative;
    display:inline-block;
    padding-right:39px;
    font-weight:700;
    color:@septenary-color-xxdark;
    text-transform:uppercase;

    &:hover{
        text-decoration:none;
    }

    [class*="fm-icon-"]{
        position:absolute;
        right:0;
        top:-5px;
        font-size:25px;
    }
}

/*************************************************/
/* modal login */

.header-login-modal{
    bottom:auto;
    .close {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .modal-body{
        padding: 20px 45px;
        overflow-y:auto;
        max-height: none;
    }
    &.out {
        .bounceOutUp
    }
}

.modal-login-facebook-bt{
    width:100%;
}

.header-login-modal-welcome {
    padding: 0;
    margin: 0;
    .has-text {
        margin: 10px 0;
    }
}

.header-login-modal-divider{
    margin:20px 0;
}

.user-login-iframe{
    width:100%;
    min-height:410px;
    overflow: hidden;
}


/*************************************************/
