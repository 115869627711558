@font-face {
    font-family: 'museo_sans';
    src: url('/static/css/fonts/museo-bold/museo-sans-700.eot');
    src: url('/static/css/fonts/museo-bold/museo-sans-700.eot?#iefix') format('embedded-opentype'),
    url('/static/css/fonts/museo-bold/museo-sans-700.woff2') format('woff2'),
    url('/static/css/fonts/museo-bold/museo-sans-700.woff') format('woff'),
    url('/static/css/fonts/museo-bold/museo-sans-700.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
