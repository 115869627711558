@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/*************************************************/
/* social media links */

.social-media-links {
    display: inline-block;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        margin-left: 9px;

        &:first-child {
            margin-left: 0;
        };
    }
}

.social-media-link {
    display: block;
    height: 30px;
    position: relative;
    width: 30px;

    &::before {
        background-color: @link-color;
        bottom: 100%;
        content: "";
        left: 50%;
        position: absolute;
        right: 50%;
        top: 100%;

        .transition(.1s, ease-in);
    }

    &:hover {
        text-decoration: none;

        &::before {
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            .transition(.1s, ease-out);
        }
    };

    [class*="fl-icon-"] {
        color: @social-media-icon-color;
        position: absolute;
    }

    &.is-labeled {
        border-radius: 2px;
        color: white;
        font-size: 16px;
        height: auto;
        padding: 10px 25px 10px 40px;
        position: relative;
        width: auto;
        z-index: 0;

        &:hover {
            &::before {
                z-index: -1;
            }
        }

        [class*="fl-icon-"] {
            bottom: auto;
            font-size: 20px;
            left: 13px;
            margin: 0;
            right: auto;
            top: 9px;
        }
    }
}

.social-media-link-fb {
    .social-media-link;
    background: @facebook-color;

    [class*="fl-icon-"] {
        bottom: 0;
        font-size: 25px;
        right: -3px;
    }
}

.social-media-link-twitter {
    .social-media-link;
    background: @twitter-color;

    [class*="fl-icon-"] {
        font-size: 20px;
        margin-left: 6px;
        margin-top: 5px;
    }
}

.social-media-link-youtube {
    .social-media-link;
    background: @youtube-color;

    [class*="fl-icon-"] {
        font-size: 22px;
        margin-left: 5px;
        margin-top: 4px;
    }
}

.social-media-link-rss {
    .social-media-link;
    background: @rss-color;

    [class*="fl-icon-"] {
        bottom: 3px;
        font-size: 20px;
        left: 3px;
    }
}

.social-media-link-instagram {
    .social-media-link;
    background: @instagram-color;

    [class*="fl-icon-"] {
        bottom: 4px;
        font-size: 23px;
        left: 5px;
    }
}

.social-media-vote-article {
    .social-media-link;
    background: @septenary-color-xxdark;

    [class*="fl-icon-"] {
        bottom: 4px;
        font-size: 23px;
        left: 5px;
    }
}
