@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/* popover link */

.popover-more-link {
    display: block;
    padding: 12px 17px;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: @border-color-light;
    text-align: center;
    color: @link-color;
    font-weight: bold;
    .type(detail);
}
