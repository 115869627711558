@font-face {
    font-family: 'museo_sans';
    src: url('/static/css/fonts/museo-light/museo-sans-300.eot');
    src: url('/static/css/fonts/museo-light/museo-sans-300.eot?#iefix') format('embedded-opentype'),
    url('/static/css/fonts/museo-light/museo-sans-300.woff2') format('woff2'),
    url('/static/css/fonts/museo-light/museo-sans-300.woff') format('woff'),
    url('/static/css/fonts/museo-light/museo-sans-300.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
