/* -----------------------
Only to be edited by the design team - style changes here echo across the whole site.
-------------------------- */
@import (reference) "../../theme/variables.less";
@import (reference) "../theme/variables.less";
@import (reference) "../../libs/mixins.less";

@import "../../libs/normalize.less";
@import "../../libs/ceaser.custom.less";
@import "libs/bootstrap.3.2.grid.less";

@font-size : 14px;

html{
    font-size: @font-size;
    .primary-font;
    color:@text-color-dark;
}

html,
body{
    padding: 0;
    margin:0;
    //font optimization for macs
    -webkit-font-smoothing:antialiased;
    text-rendering: optimizeLegibility;
}

body{
    height:@header-height;
    background-color:@quaternary-color-dark;
}

*,
input[type="search"] {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

strong{
    font-weight: 700;
}

a{
    color:@link-color;
    text-decoration: none;
    .transition(~"color .1s ease-out");
    &:hover{
        text-decoration: underline;
        .transition(~"color .1s ease-out");
    };
    cursor: pointer;
}

em{
    font-style: normal;
}

/*  1. Don't go below 12px for fonts */

small{
    line-height:16px;
    font-size: 12px; /* 1. */
}

h1, h2, h3, h4, h5, h6{
    .primary-font;
    margin-top: 0;
}

code{
    font-weight:700;
}

code{
    font-weight:700;
}

button{
    .transition(~".1s ease-out");
}

textarea:focus,
input:focus{
    outline: 0;
}

*:focus {
    outline: 0;
}

h1{
    margin-bottom: 26px;
    font-size: 38px;
    line-height:38px;
}

h2{
    margin-bottom: 22px;
    font-size: 32px;
}

h3{
    margin-bottom: 20px;
    font-size: 26px;
}

h4{
    margin-bottom: 18px;
    font-size: 22px;
}

h5{
    margin-bottom: 16px;
    font-size: 18px;
}

h6{
    margin-bottom: 14px;
    font-size: 14px;
}

p {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    line-height:20px;
    margin-bottom: 20px;
}

fieldset{
    border:none;
}

main{
    padding-top:@header-height;
    background-color:@site-bg-color;
}

.main-drawer{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    min-width:@site-width-fixed;
    .transition(.3s, ease-out-quart);

    .easeOutQuad(@usernav-ani-time);

    .has-open-menu &{
        left:-@usernav-width;
        right:@usernav-width;
    }
}

.fm-row{
    .make-row();
}

.btn{
    .primary-font;
}

.modal-backdrop{
    background-color:@quaternary-color;
    opacity:.8 !important;
}

.chat-window {
    display:none;
}

.chatbox-wrapper {
    display:none;
}
