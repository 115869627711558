@import (reference) "theme/variables.less";

.steps {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;

    &.is-complete {
        li {
            &:before {
                border-color: @primary-color;
                background: @primary-color;
            }
            &:after {
                background: @primary-color;
            }
        }
    }
}

.step {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;

    &:first-child {
        width: 15px;

        &:after {
            display: none;
        }
    }

    &:before,
    &:after {
        content: '';
        display: inline-block;
        position: absolute;
    }

    /* circle */
    &:before {
        right: 0;
        width: 15px;
        height: 15px;
        border: 1px solid @quaternary-color-xdark;
        background: @quaternary-color-xdark;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        z-index: 2;
    }

    /* connector line */
    &:after {
        left: 0;
        top: 50%;
        z-index: 1;
        width: 14px;
        height: 1px;
        background: @quaternary-color-xlight;
    }

    &.is-current {
        /* current step */
        &:before {
            border-color: @quaternary-color-xlight;
            background: @quaternary-color-xdark;
        }

        /* next steps */
        & ~ .step {
            &:before {
                border-color: @quaternary-color-xlight;
                background: none;
            }
        }
    }
}
