@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

[class*="payment-icon"] {
    display: inline-block;
}

.payment-icon-mastercard {
    background: url("/static/css/images/icons/cc-mastercard.svg") no-repeat;
    height: 40px;
    width: 62px;
}

.payment-icon-visa {
    background: url("/static/css/images/icons/cc-visa.svg") no-repeat;
    height: 40px;
    width: 62px;
}

.payment-icon-amex {
    background: url("/static/css/images/icons/cc-amex.svg") no-repeat;
    height: 40px;
    width: 62px;

}

.payment-icon-jcb {
    background: url("/static/css/images/icons/cc-jcb.svg") no-repeat;
    height: 40px;
    width: 62px;
}

.payment-icon-visa-electron {
    background: url("/static/css/images/icons/cc-visa-electron.svg") no-repeat;
    height: 40px;
    width: 62px;
}

.payment-icon-delta {
    background: url("/static/css/images/icons/cc-delta.svg") no-repeat;
    height: 40px;
    width: 62px;
}

.payment-icon-paypal {
    background: url("/static/css/images/icons/paypal.svg") no-repeat;
    height: 40px;
    width: 155px;
}

.payment-icon-skrill {
    background: url("/static/css/images/icons/skrill.svg") no-repeat;
    height: 40px;
    width: 117px;
}

.payment-icon-alipay {
    background: url("/static/css/images/icons/alipay.svg") no-repeat;
    height: 40px;
    width: 114px;
}

.payment-icon-unionpay {
    background: url("/static/css/images/icons/unionpay.svg") no-repeat;
    height: 40px;
    width: 114px;
}

.payment-icon-ideal {
    background: url("/static/css/images/icons/ideal.svg") no-repeat;
    height: 40px;
    width: 114px;
}

.payment-icon-sofort {
    background: url("/static/css/images/icons/sofort.svg") no-repeat;
    height: 40px;
    width: 114px;
}

.payment-icon-giropay {
    background: url("/static/css/images/icons/giropay.svg") no-repeat;
    height: 40px;
    width: 114px;
}

.payment-icon-interac {
    background: url("/static/css/images/icons/interac.svg") no-repeat;
    height: 40px;
    width: 114px;
}

.payment-icon-bancontact {
    background: url("/static/css/images/icons/bancontact.svg") no-repeat;
    height: 40px;
    width: 114px;
}

.payment-icon-dotpay {
    background: url("/static/css/images/icons/dotpay.svg") no-repeat;
    height: 40px;
    width: 114px;
}

.payment-icon-paytm {
    background: url("/static/css/images/icons/paytm.svg") no-repeat;
    height: 40px;
    width: 114px;
}

.payment-icon-webmoney {
    background: url("/static/css/images/icons/webmoney.svg") no-repeat;
    height: 40px;
    width: 154px;
}

.payment-icon-amex-security {
    background: url("/static/css/images/icons/cc-cvv.svg") no-repeat;
    height: 40px;
    width: 60px;
}

.payment-icon-cc-security {
    background: url("/static/css/images/icons/cc-ccv.svg") no-repeat;
    height: 40px;
    width: 60px;
}

.payment-icon-placeholder {
    background: url("/static/css/images/icons/cc-placeholder.svg") no-repeat;
    height: 40px;
    width: 62px;
}

.payment-icon-eba {
    background: url("/static/css/images/icons/vba-eba.svg") no-repeat;
    height: 40px;
    width: 62px;
}

.payment-icon-teambilling {
    background: url("/static/css/images/icons/vba-teambilling.svg") no-repeat;
    height: 40px;
    width: 62px;
}

.payment-icon-geotrust-logo {
    .spritemap-payment;
    background-position: 0px -136px;
    height: 27px;
    width: 165px;
}

.padlock-icon {
    background: url("/static/css/images/payment/padlock.png") no-repeat;
    display: inline-block;
    height: 58px;
    width: 62px;
}
