@import (reference) "../theme/variables.less";
@import (reference) "../../libs/mixins.less";
@import (reference) "libs/bootstrap.3.2.grid.less";
@import "../../flux/modules/promotion-flags.less";
@import "../../flux/modules/toggle-switch.less";
@import "../modules/jquery-ui-custom/slider.less";
@import "../modules/simple-tabs.less";

/* Animations */
@import "../../mixins/animated/fadeInDown.less";

/*******************************************************/
    /*******************************************************/
    /** Sites search page **/

    .sites-search-table {
        .primary-font;
        float: right;
        width: 78% !important;

        .sites-browse-tabs {
            height: 44px;

            & > li {

                & > a {
                    padding: 12px 10px 13px 10px;
                }

                .pseudo-select-options {
                    width: 140px;
                    top: 42px;
                    left: -15px;
                }
            }

            .pagination-select {
                float: right;
            }
        }
    }

    .sites-search-intro-inner{
        .site-section-inner;
        position:relative;
        padding-top:50px;
    }

    .sites-search-table-heading{
        display:inline-block;
        margin-bottom: 3px !important;
    }

    .sites-search-sell-btn {
        float:right;
        margin-top:10px;
    }

    .sites-search-table-subheading{
        display: block;
        font-size: 18px;
        margin-bottom:35px;
    }

    .sites-search-table-pagination{
        display: inline-block;
    }

    .sites-search-table-inner{
        padding-left: 55px;
    }

    .sites-search-table-errors {
        text-align: center;
        margin-bottom: 20px;
        color: #de4062;
    }

    /* Filters */

    .sites-filters {
        .primary-font;
        float: left;
        width: 22%;
    }

    .sites-filters{
        .table-header {
            background: transparent;
        }
        .table-header-pagination {
            width: auto;
            display: inline-block;;
        }
        .pseudo-select {
            padding: 13px 10px;
        }
        .pseudo-select-options {
            display: none;
        }
        .pseudo-select-value {
            width: auto;
            line-height: 14px !important;
        }
        .table-filter-select{
            width:100%;
        }

        .table-filters{
            border: 0;
            display: block;
            .clearfix;
            background: @septenary-color-xxxlight;
        }
        .item-filter,
        .category-filter,
        .listing-filter,
        .subcategory-filter{
            .make-xs-column(3);
            padding: 25px 15px;
        }
        .subcategory-filter{
            padding-top:51px;
            .table-filter-select{
                .animated(.2s);
                .fadeInDown;
            }
        }
        .submit-filters{
            text-align: right;
            padding-right: 30px;
        }
        .submit-filters-btn{
            display: inline-block;
            margin-top: 26px;
        }
        .psuedo-select{
            font-weight:700;
        }
    }

    .table-filters{
        font-size: 14px;
        .psuedo-select-options{
            width:auto;
            min-width:180px;
        }
    }

    .table-filter-set {
        padding-bottom: 45px;
        border: none !important;
        &:first-child {
            margin-top: 19px;
            .table-filter-label {
                border-bottom: 1px solid @septenary-color-xlight;
            }
        }
    }

    .table-filter-label {
        display: block;
        padding: 13px 0;
        text-transform: uppercase;
        border-bottom: 2px solid lighten(@quaternary-color-xxxlight, 15%);
    }

    .table-filter-statistics-label {
        text-transform: none;
        font-weight: 300;
    }

    /* Search Filter */

    .input-icon {
        margin-top: 25px;
    }

    .input-custom-search {
        height: 52px !important;
        .primary-font;
    }

    .input-icon:before {
        width: 30px;
        font-size: 15px;
    }

    .input-icon input {
        padding-left: 45px !important;
        font-size: 14px;
    }

    /* Save Filter Button */

    .btn-table-filter-save {
        width: 100%;
        margin: 10px 0;
        padding: 10px 12px;
        .primary-font;
    }

    /* Saved filter tags and domain extensions */

    .table-filter-saved-item {
        display: table;
        width: 100%;
        height: 40px;
        margin: 10px 0;
        padding: 10px 12px 9px;
        font-size: 14px;
        color: @septenary-color-xxdark;
        border: 1px solid @border-color-light;
        box-shadow: inset 0 0 3px lighten(@quaternary-color-xxxlight, 25%);
        .border-radius(3px, 3px, 3px, 3px);
        [class^="fl-icon-"] {
            display: table-cell;
            text-align: right;
            vertical-align: middle;
            font-size: 8px;
            min-width: 25px;
        }

        &:hover,
        &.is-selected {
            background: @link-color;
            color: @site-text-color-alt;
            font-weight: 700;
            text-decoration: none;
            border: 1px solid @link-color;
            box-shadow: none !important;
        }

        .save-filter-envelope {
            font-size: 11px;
            position: relative;
            top: -.8px
        }

       .save-filter-name {
            line-height: 1;
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .table-custom-filter-list {
        margin-top: 10px;
    }

    .table-custom-filter-item {
        display: inline-block;
        width: auto;
        height: 40px;
        margin: 10px 5px 0 0;
        padding: 10px 12px;
        font-size: 14px;
        color: @septenary-color-xxdark;
        vertical-align: bottom;
        border: 1px solid @border-color-light;
        box-shadow: inset 0 0 3px lighten(@quaternary-color-xxxlight, 25%);
        .border-radius(3px, 3px, 3px, 3px);
        [class^="fl-icon-"] {
            float: right;
            display: inline-block;
            height: 20px;
            padding-left: 15px;
            line-height: 20px;
            vertical-align: middle;
            font-size: 8px;
        }
        &:hover,
        &.is-selected {
            background: @link-color;
            color: @site-text-color-alt;
            font-weight: 700;
            text-decoration: none;
            border: 1px solid @link-color;
            box-shadow: none !important;
        }
    }

    /* Filter checkbox options */

    .table-filter-options {
        overflow: hidden;
        label {
            display: block;
            padding: 7px 0;
            font-size: 14px;
            font-weight: 300 !important;
            .is-inline& {
                width: 33%;
                float: left;
            }
        }
        input[type="checkbox"] {
            margin-right: 5px;
        }
    }

    .table-custom-filter {
        overflow: hidden;
        width: 100%;
    }

    .input-custom-filter {
        float: left;
        width: 70% !important;
        .primary-font !important;
    }

    .btn-input-custom-filter {
        float: right;
        width: 27%;
        padding: 8px 5px;
        font-size: 14px;
    }

    /* UI slider */

    .ui-slider {
        margin: 15px 10px 30px 10px;
    }

    .revenue-filter,
    .visits-filter,
    .price-filter {
        margin: 15px 0;
    }

    .table-filter-range {
        float: right;
        width: auto;
        font-weight: normal;
        color: @septenary-color-dark;
    }

    .sites-landing-featured {
        .table-filters,
        .table-header-filter-toggle {
            display: none !important; // override style set by js on element
        }
    }

    /* Table */

    .sites-table{
        width:100%;
        .promotion-flags{
            vertical-align:top;
            li{
                margin-top:-1px;
            }
        }

        thead {
            border-color: @septenary-color-xlight;
        }

        tbody {
            border-bottom-color: @septenary-color-xlight;

            tr {
                border-top: 1px solid @septenary-color-xlight;
                border-left: 1px solid @septenary-color-xlight;
                border-right: 1px solid @septenary-color-xlight;
            }
        }

        th,
        td{
            &:nth-child(1){
                padding-left: 16px;
                &:after{
                    left:53px;
                }
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5){
                text-align: right;
                padding-right: 0;
                padding-left: 24px;
            }

            &:nth-child(5):after{
                right:33px;
            }
            &:nth-child(6):after{
                left:auto;
                right:26px;
            }
            &:nth-child(7):after{
                left:auto;
                right:45px;
            }
            &.table-no-value{
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5){
                    &:before{
                        left: auto;
                        right: 0;
                        margin-left: 0;
                    }
                }
            }
            &:nth-child(5){
                padding-right: 22px;
            }
            &:nth-child(6){
                border-left: 1px solid @septenary-color-xlight;
            }
        }
        tr:hover{
            cursor:pointer;
            td:nth-child(6){
                border-left: 1px solid darken(@primary-color-xxxxlight, 10%);
            }
        }
        .table-row-hl{
            td:nth-child(6){
                border-left: 1px solid  @table-hl-border-color;
            }
        }
        th{
            white-space: nowrap;
            &:nth-child(1){
                text-align: left;
            }
            &:nth-child(6){
                border-left: 0 none;
            }
        }
        td{
            &:nth-child(1){
                padding-left:0;
            }
            &:nth-child(6),
            &:nth-child(7){
                color:@quaternary-color-xlight;
            }
        }
    }

    .site-table-label{
        display: block;
        margin-top: 4px;
    }

    .sites-search-table-buynow{
        .sites-search-table-value{
            color:@quinary-color;
        }
    }

    .sites-search-table-url-inner{
        position:relative;
        padding-left:55px;
    }

    .sites-search-table-url{
        max-width:350px;

        .promotion-tags{
            display: inline-block;
            margin: 1px 0 3px;
        }
    }

    .sites-search-table-icon{
        .sites-spritemap;
        position:absolute;
        top:0;
        left:12px;
        display:inline-block;
        width:32px;
        &.sites-website-icon{
            height:30px;
            background-position: 0 -900px;
        }
        &.sites-domain-icon{
            height:32px;
            background-position: -50px -900px;
        }
        &.sites-app-icon{
            height:29px;
            background-position: -100px -900px;
        }
    }

    .sites-search-table-link{
        display: inline-block;
        margin-bottom: 2px;
        font-weight:700;
        max-width:325px;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .sites-search-table-desc{
        display: block;
        width: 260px;
        margin-top:2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
    }


    .sites-search-table-price-hl{
        color: @quinary-color;
    }

    .sites-search-table-value{
        display: block;
        white-space: nowrap;
    }

    .sites-search-table-buynow{
        .sites-search-table-value {
           color: @quinary-color;
        }

    }

    .sites-search-view-more{
        padding: 58px 0 125px;
        text-align: center;
    }

    .sites-save-search-modal {
        .error {
            margin: 10px 0 10px 15px;
            color: @secondary-color-xdark;
        }
    }

    .sites-save-search-email-preference {
      float: left;
      padding-top:13px;
    }

    .sites-save-search-checkbox {
      vertical-align: middle;
      margin-bottom:2px;
    }

    .sites-save-search-checkbox-label {
      padding-left: 2px;
    }
