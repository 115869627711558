@font-face {
    font-family: 'museo_sans';
    src: url('/static/css/fonts/museo-reg/museo-sans-500.eot');
    src: url('/static/css/fonts/museo-reg/museo-sans-500.eot?#iefix') format('embedded-opentype'),
    url('/static/css/fonts/museo-reg/museo-sans-500.woff2') format('woff2'),
    url('/static/css/fonts/museo-reg/museo-sans-500.woff') format('woff'),
    url('/static/css/fonts/museo-reg/museo-sans-500.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
