@import (reference) "../../theme/variables.less";
@import (reference) "../../libs/mixins.less";
@import (reference) "../../flux/modules/buttons.less";
@import "../../flux/modules/hr-divider.less";
@import "../../flux/modules/responsive-modal.less";

/* Animations */
@import "../../mixins/animated/fadeIn.less";
@import "../../mixins/animated/bounceOutUp.less";

/*************************************************/
/* facebook signup form */

.fb-signup-welcome{
    display: table;
    margin-bottom: 20px;
}

.fb-signup-welcome-content{
    display: table-cell;
    vertical-align: middle;
}

.fb-signup-welcome-img{
    margin-right: 15px;
}

.fb-signup-welcome-title{
    margin-bottom: 0;
}

.fb-login-status{
    display: table;
}

.fb-login-status-content{
    display: table-cell;
    max-width: 216px;
    padding-left: 10px;
    vertical-align: middle;
    font-weight: bold;
}


/*************************************************/
/* modal login */

.header-login-modal{
    width: 320px;
    margin-left: -160px;
    bottom:auto;
    .animated(.4s);
    .fadeIn;
    .animation-delay(.1s);

    .close{
        position: absolute;
        right: 20px;
        top: 14px;
        font-size: 12px;
    }
    .modal-body{
        padding: 20px;
        overflow-y:auto;
        max-height: none;
    }
    &.out {
        .bounceOutUp;
    }
    .btn{
        width: 100%;
    }
    .modal-footer{
        text-align: center;
    }
    .user-login-form.fl-form{
        fieldset{
            margin-bottom: 0px;
        }
    }

}

.header-login-modal-welcome {
    padding: 0;
    margin: 0;
    .has-text {
        margin: 10px 0;
    }
}

.modal-login-facebook-bt,
.modal-login-linkedin-bt{
    .button-constructor(10px, 18px, 7px);
    width: 100%;
}

.modal-login-facebook-bt{
    .facebook-bt-skin;
}

.modal-login-linkedin-bt{
    .linkedin-bt-skin;
}

.modal-login-signup-bt{
    .button-constructor(6px, 13px, 5px);
    margin-left: 10px;
}

.modal-login-forgot-pass-bt,
.modal-login-signup-bt{
    .secondary-bt-skin;
}

.header-login-modal-divider{
    margin:20px 0;
    .hr-divider;
}



/*************************************************/
/* animated form field slide in */

/* 1. holds animation till modal has loaded */

.facebook-signup-form,
.user-login-form,
.user-signup-form{
    .signup-objective-label {
        margin-bottom: 0px;
    }
}


.signup-objective{
    .btn{
        width: 50%;
    }
}
