@import (reference) "../theme/variables.less";
@import "libs/bootstrap.3.2.grid.less";
@import "../modules/user-avatar.less";
@import "../../flux/modules/steps.less";
@import "../../flux/modules/discussion.less";

    /*******************************************************/
    /** Logos **/

    .logo-template{
        display:inline-block;
        vertical-align:top;
    }

    .logo-cc{
       .logo-template;
        .fm-logo-sprite;
        width:122px;
        height:22px;
        background-position:0 -160px;
    }

    .logo-paypal{
        .logo-template;
        .fm-logo-sprite;
        width:85px;
        height:23px;
        background-position:0 -190px;
    }

    .logo-wire{
        .logo-template;
        .fm-logo-sprite;
        width:96px;
        height:24px;
        background-position:0 -220px;
    }

    .logo-skrill{
        .logo-template;
        .fm-logo-sprite;
        width:66px;
        height:22px;
        background-position:0 -250px;
    }

    .logo-webmoney{
        .logo-template;
        .fm-logo-sprite;
        width:68px;
        height:22px;
        background-position:0 -280px;
    }

 /**************************************/
/* header */

.handover-header{
    background-color:@quaternary-color-xdark;
    padding:80px 0 50px;
    .primary-font;

    .section-inner{
        .clearfix;
    }
}

.handover-header-inner{
    .make-row();
    display:table;
    width:100%;
}

.handover-intro{
    .make-xs-column(6);
    display:table-cell;
    vertical-align:bottom;
}

.handover-title{
   color:@quaternary-color;
   font-size:14px;
   margin-bottom:4px;
   text-transform:uppercase;
}

.handover-url{
    font-size:32px;
    color:@septenary-color-xxxlight;
    font-weight:700;
}

.handover-info{
    .make-xs-column(6);
    display:table-cell;
    vertical-align:bottom;
    text-align:right;
}

.handover-info-details{
    display:block;
    padding-top:22px;
    color:@quaternary-color;
    text-transform:uppercase;
    font-weight:700;

    .user-avatar{
        margin-left:10px;
        vertical-align:top;
    }
}

.handover-info-username,
.handover-info-price{
    margin:0 10px;
    color:@septenary-color-xxxlight;
    font-size:20px;
    text-transform:none;
}

.handover-info-price{
    margin-right:5px;
}

.handover-info-currency{
    color:@septenary-color-xxxlight;
    font-weight:500;
}
/**************************************/
/* step counter */

.handover-pagination{
    .make-row();
    padding:30px 0;
    .primary-font;
    text-transform:uppercase;
    border-bottom:1px solid @septenary-color-xxlight;

    .is-complete{
        margin-right:18px;
    }
}

.handover-pagination-count{
    margin:0 18px;
    display:inline-block;
    font-weight:700;
    font-size:16px;
    color:@quaternary-color-xxlight;
    white-space: nowrap;

    &.is-complete{
        color:@primary-color;
    }
}

.handover-pagination-title{
    display:inline-block;
    margin:0;
    font-weight:700;
    font-size:16px;
    color:@primary-color;
    text-transform:uppercase;
}

.handover-alert {
    margin: 50px -10px 0 -10px;
}

/**************************************/
/* handover steps */

.handover-step{
    .make-row();
    padding:50px 0;
    min-height:250px;
}

.handover-step-title{
    font-size:28px;
}

.handover-more-info{
    padding:30px 0 70px;
    margin:70px 0 0;
    border-top:1px solid @septenary-color-xxlight;
    list-style:none;
    > li{
        .make-xs-column(4);

        &.handover-widthdrawl-methods{
            .make-xs-column(8);
            padding-right:15%;
        }
    }
}

.handover-more-info-title{
    margin-bottom:25px;
    font-size:14px;
    text-transform:uppercase;
}

.handover-payment-method{
    margin:0;
    padding:0;
    list-style:none;

    li{
        display:inline-block;
        margin:0 20px 20px 0;
    }
}

 /**************************************/
/* step 1 */

.send-payment-step{
    .handover-more-info-higher{
        padding-top:42px;
    }
}



 /**************************************/
/* step 3 */

.handover-confirm{
    position:relative;
    padding-left:80px;

    .user-avatar{
        position:absolute;
        left:0;
        top:0;

        &, img {
            width:50px;
            height:50px;
        }
    }
}

.handover-confirm-text{
    color:@text-color-dark;
}

.handover-confirm-input{
    width:136px !important;
    margin-right:15px;
}


 /**************************************/
/* handover discussion */

.handover-discussion{
    padding:60px;
    background-color:@septenary-color-xxlight;

    .discussion-thread-message-remove-bt {
        display: none !important;
    }

    .discussion-thread-reveal-inner {
        background-color: @septenary-color-xxlight;
    }

    .discussion-thread > .discussion-thread-message {
        &.has-replies > .discussion-thread-reveal-bt .discussion-thread-reveal-inner {
            display: inline;
        }

        .discussion-thread-reveal-bt .discussion-thread-reveal-inner {
            display: none;
        }
    }
}

.handover-discussion-title{
    font-size:14px;
}
