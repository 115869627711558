@import (reference) "../theme/variables.less";

.user-avatar{
    overflow:hidden;
    display:inline-block;
    width:40px;
    height:40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color:@quaternary-color-xxlight;

    img{
        width:40px;
        height:40px;
    }

    &.is-small{
        width:24px;
        height:24px;

        img{
            width:24px;
            height:24px;
        }
    }
}
