/**
 * NOTE: This file is a dump of Freelancer's variables.less at the time this
 * commit was made with some bits replaced with Freemarket specific values
 * and other variables not shared with Freelancer's variables dumped below.
 */

@import (reference) "theme/typography.less";

//******************************************************
/*  READ ME FIRST -----------------------------------------------------------------------------------------------------------------------------
    The variables file defines our brand and dictates the colors, fonts and sprites to be used across our main site and subsites.
    No color or font definitions are to be made outside this file.
    If you need to add a custom color to the site come and talk to Design first.
    Either that color will be added to our style guide or you will need to use one of our pre-existing colors instead.
    Any colors or fonts defined outside this file will be deleted with extreme prejudice, and the design team will kill a kitten in retribution.

      )\._.,--....,'``.
     /,   _.. \   _\  ;`._ ,.
    <._.-(,_..'--(,_..'`-.;.'

    You have been warned.
*/
//******************************************************

//******************************************************
// Body Font
//******************************************************
@body-size: 16px;
@tertiary-font-reg: 'georgia';
@monospaced-font: 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;

//******************************************************
// Section
/* 1. The max width of the site in fixed width mode - this is the site default
   2. The max width of the site in responsive mode - triggered byzz adding is-resposive to the HTML element
      The site will be resposnive when the window is > @site-width-fixed in width
   3. This represents the max width catered to for mobile devices
   4. This is the minimum device width for smartphones */
//******************************************************
@site-width-fixed: 960px; /* 1 */
@site-maxwidth-responsive: 1176px; /* 2 */
@site-maxwidth-mobile: 640px; /* 3 */
@site-minwidth-responsive: 320px; /* 4 */
@site-section-padding: 20px;
@site-content-vertical-padding: 36px;
@header-height: 72px;
@sidebar-width: 280px;
@subheader-height: @header-height;
@contact-list-width:220px;
@sidebar-mode-width:@contact-list-width;


//******************************************************
// Contact Bar
//******************************************************
@contactbar-width: 260px;

//******************************************************
// Colors
//******************************************************

/* Blues */

@primary-color-xxxxlight:#fff5e6;
@primary-color-xxxlight:#ffecce;
@primary-color-xxlight:#ffd99c;
@primary-color-xlight:#ffc66b;
@primary-color-light:#ffb339;
@primary-color:#ffa008;
@primary-color-dark:#ff8807;
@primary-color-xdark:#ff7006;
@primary-color-xxdark:#ff5804;
@primary-color-xxxdark:#ff4003;

/* Pinks */

@secondary-color-xxxlight:#ffc4d9;
@secondary-color-xxlight:#ff8eb6;
@secondary-color-xlight:#ff588e;
@secondary-color-light:#ff437d;
@secondary-color: #f52068;
@secondary-color-dark:#e91260;
@secondary-color-xdark:#e3005b; // Please don't use this colour
@secondary-color-xxdark:#dc0057; // Please don't use this colour
@secondary-color-xxxdark:#b20142;

/* Oranges */

@tertiary-color-xxxlight:#fffad4;
@tertiary-color-xxlight:#99c8dd;
@tertiary-color-xlight:#66accb;
@tertiary-color-light:#3391ba;
@tertiary-color:#0075a9;
@tertiary-color-dark:#006492;
@tertiary-color-xdark:#00527b;
@tertiary-color-xxdark:#004064;
@tertiary-color-xxxdark:#002f4d;

@quaternary-color-xxxxxlight: #F7F7F7;
@quaternary-color-xxxxlight: #F0F0F0;
@quaternary-color-xxxlight:#aeaeae;
@quaternary-color-xxlight:#9a9a9a;
@quaternary-color-xlight:#868686;
@quaternary-color-light:#727272;
@quaternary-color:#5d5d5d;
@quaternary-color-dark:#4a4a4a;
@quaternary-color-xdark:#353535;
@quaternary-color-xxdark:#2a2a2a;
@quaternary-color-xxxdark:#202020;

/* Green */

@quinary-color-xxxlight:#ddf5d3;
@quinary-color-xxlight:#c2ebbc;
@quinary-color-xlight:#95de90;
@quinary-color-light:#72cc72;
@quinary-color:#5dc26a;
@quinary-color-dark:#4fb55d;
@quinary-color-xdark:#379e54;  // Please don't use this colour
@quinary-color-xxdark:#2a934d; // Please don't use this colour
@quinary-color-xxxdark:#2f6543;

/* Red */

@senary-color-xxxlight: #ffcdc7;
@senary-color-xxlight: #ffb7a3;
@senary-color-xlight: #ff624d;
@senary-color-light: #f84438;
@senary-color: #eb3730;
@senary-color-dark: #e02a28;
@senary-color-xdark: #d31721; // Please don't use this colour
@senary-color-xxdark: #c20014; // Please don't use this colour
@senary-color-xxxdark: #891b21;

/* Mono */

@septenary-color-xxxlight:#fff; // white
@septenary-color-xxlight:#eee;
@septenary-color-xlight:#ccc;
@septenary-color-light:#aaa;
@septenary-color:#767676; // grey
@septenary-color-dark:#555;
@septenary-color-xdark:#444;
@septenary-color-xxdark:#333;
@septenary-color-xxxdark:#000;

/* Yellow */

//******************************************************
// Yellow
//******************************************************

@octonary-color-light:#fffb4c;
@octonary-color: #ffeb3b;
@octonary-color-dark: #ffcc00;

@nonary-color-light:#8c3cd6;
@nonary-color: #7d30c9;
@nonary-color-dark: #6c1db7;
@denary-color-light:#30dbe3;
@denary-color: #00cdd4;
@denary-color-dark: #1eb5bd;


//******************************************************
// General Settings
//******************************************************

/* Alpha values to use in fade() functions */
@alpha-value-light: 20%;
@alpha-value: 50%;
@alpha-value-dark: 70%;

@text-color-light:@quaternary-color-xxxxxlight;
@text-color-mid:@quaternary-color-xlight; // used for notes
@text-color-dark: @quaternary-color-xdark; // used for body text
@text-color-xdark:@quaternary-color-xdark; // used for headers text

@bg-color-light: @quaternary-color-xxxxxlight;
@bg-color: @quaternary-color-xxxxlight;
@site-bg-color:#ffffff;
@site-bg-color-mid:@quaternary-color-xxxxxlight;
@site-bg-color-dark:@quaternary-color-xxxxlight;
@site-bg-color-xdark:@quaternary-color-xxxxlight;

@border-color-light: @quaternary-color-xxxlight; // used for general UI borders
@border-color-dark: @quaternary-color-xxlight; // used only for input fields
@border-radius: 4px;

@primary-nav-link-color:@septenary-color-xxxlight;
@subnav-hover-color:@quaternary-color-xxxxlight;
@dropdown-menu-hover:@subnav-hover-color;

@positive-color: @quinary-color;
@positive-color-light: @quinary-color-light;
@average-color:@tertiary-color;
@negative-color: @senary-color;
@alert-color: #ff4003;


/* Freelancer Branded Btn */
@hireme-bg-color: @septenary-color-xxxlight;

/* Footer bg color */
@footer-bg-color: @quaternary-color-xxdark;


@hover-bg-color:@primary-color-xxxxlight;

/* Used in PVP it will be updated with the new rating design */
@user-rating-color : #F77D0E;

@common-notify-color: @quaternary-color-xxlight;
@common-notify-color-light: @quaternary-color-xxxlight;
@common-notify-content-color: @quaternary-color-xxxdark;

@success-notify-color: @quinary-color-xxlight;
@success-notify-color-light: @quinary-color-xxxlight;
@success-notify-content-color: @quinary-color-xxxdark;

@error-notify-color: @senary-color-xxlight;
@error-notify-color-light: @senary-color-xxxlight;
@error-notify-content-color: @senary-color-xxxdark;

@warning-notify-color: @tertiary-color-xxlight;
@warning-notify-color-light: @tertiary-color-xxxlight;
@warning-notify-content-color: @tertiary-color-xxxdark;

@bid-notify-color: @primary-color-xxxlight;
@bid-notify-color-light: @primary-color-xxxxlight;
@bid-notify-content-color: @primary-color-xxxdark;

@info-notify-color: @primary-color-xxxlight;
@info-notify-color-light: @primary-color-xxxxlight;
@info-notify-content-color: @primary-color-xxxdark;

@credits-color:@octonary-color-dark;
@xp-color:@quinary-color;

@link-color: #006ebf;
@link-color-hover: @primary-color;

@online-color:@positive-color;
@offline-dot-color:@quaternary-color-xxlight;
@offline-color:@quaternary-color-xlight;


/* Promotion tags - these change promotion tag and flag colur only, use hex values.
Will change across both Freelancer and Freemarket, and other products in future. */

@sponsored-color:#f5c022;
@recommended-color:#f5c022;
@featured-color:#eb860e;
@buy-now-color:#8cbf74;
@hot-deal-color:#e22a2a;
@private-color:#ffa33b;
@assisted-color:#895acc;
@recruiter-color:#895acc;
@urgent-color:#d22c02;
@highlight-color:#18d3de;
@qualified-color:#8A5EC6;
@sealed-color:#66c8ec;
@fulltime-color:#71be7c;
@prepaid-color:#549037;
@nda-color:#006ca5;
@local-color:@primary-color-dark;
@top-color:#1f2329;
@guaranteed-color:#33a82d;
@extend-color:@septenary-color-xlight;
@topContest-color:@quaternary-color;
@management-color: #1eb5bd;

/* Contact list */
@contact-list-bg:@quaternary-color-xxxxlight;
@contact-list-light: @quaternary-color-xxxxxlight;
@contact-list-dark: @quaternary-color-xxxlight;

@input-bg: @septenary-color-xxxlight;
@input-bg-disabled: darken(@input-bg, 3%);
@input-text-color: @text-color-xdark;
@field-border-color: @border-color-dark;
@field-focus-color: @primary-color;
@field-valid-color: @quinary-color-dark;
@field-warning-color: @tertiary-color-xxxdark;
@field-error-color: @alert-color;
@field-help-color: @tertiary-color-dark;
@field-border-error-color: @alert-color;
@placeholder-text-color: @text-color-mid;
@form-field-border-radius: 3px;
@form-field-border-radius-xxlarge: @form-field-border-radius + 3px;

/* Search Microphone */
@chrome-speech-icon-color:@text-color-mid;

@overlay-color: rgba(25, 32, 45, 0.7);

@table-hl-color:#fffdd8;
@table-hl-border-color:#e5e4c0;

@fantero-color:#444444;
@freemarket-color:#ff8e01;
@warrior-color:#d00000;

/* Social media */
@social-media-icon-color:#fff;
@facebook-color:#3b5998;
@linkedin-color:#1683bb;
@twitter-color:#25a8e0;
@youtube-color:#ce322e;
@rss-color:#ef802e;
@instagram-color:#2e5e86;
@reddit-color:#ff4500;

/* Cards shadow */
@card-shadow-level-1: fade(@septenary-color-xxxdark, 8%);
@card-shadow-level-1-secondary: fade(@septenary-color-xxxdark, 12%);
@card-shadow-level-2: fade(@septenary-color-xxxdark, 12%);
@card-shadow-level-2-secondary: fade(@septenary-color-xxxdark, 16%);
@card-shadow-level-3: fade(@septenary-color-xxxdark, 16%);
@card-shadow-level-3-secondary: fade(@septenary-color-xxxdark, 20%);

/* TO DO: update the site to use the level-01 and remove this variable */
@card-shadow: @card-shadow-level-1;

@color-icon-dark: @quaternary-color-xlight;
@color-icon-light: @quaternary-color-xxxxxlight;

/**
 * Freemarket-specific variables start here
 */
.primary-font{
    font-family:"museo_sans", Arial, sans-serif;
}

.primary-font-light{
    .primary-font;
     font-weight:300;
}
.primary-font-bold{
    .primary-font;
     font-weight:700;
}

.secondary-font{
    font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
}

@header-height:80px;
@usernav-width:380px;
@usernav-ani-time:.3s;


@hero-bg-color:#3f3f3f;
@site-text-color-alt: #ffffff;


.fm-logo-sprite{
    background:url(/static/css/images/sites/fm-logos.png) no-repeat;
}

.sites-spritemap{
    background:url('/static/css/images/sites/sites-spritemap.png') no-repeat;
}
