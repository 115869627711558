@import "../../flux/modules/tables.less";

.sites-terms-conditions,
.sites-fees-and-charges{
    .section-inner{
        padding:50px 0 100px;
    }
}

.sites-fees-table {
    max-width:50%;
    margin-bottom: 20px;
}
