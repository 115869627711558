@import (reference) "../../theme/variables.less";
@import (reference) "../theme/variables.less";
@import (reference) "../../flux/modules/notification-popover.less";
@import (reference) "../../flux/modules/buttons.less";

.user-nav {
    display: inline-block;
}

.user-actions{
    [data-status="has-messages"]{
        .user-messages-alert {
            display: inline-block;
            border-radius: 5px;
            width: 9px;
            height: 9px;
            position: relative;
            left: 23px;
            top: -6px;
            background-color: @primary-color;
            border: 1px solid @septenary-color-xxxlight;
        }
    }
    .user-messages-alert{
        display:none;
    }
}

.freemarket-notification-icon {
    display: inline-block;
    vertical-align: middle;
    .flicon-bell {
        width: 18px;
        height: 18px;
    }
}
.user-actions-list {
    list-style: none;
    padding: 0;
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}
#realtime-icon {
    text-decoration: none;
}
.user-notifications-popover{
    left: -160px !important;
    margin-top: 0px;
    &.popover {
        max-width: 331px;
        &.bottom {
            .arrow {
                left: 75%;
            }
        }
    }
    .notification-popover;
    .notification-item-content {
        min-height: 60px;
    }
    .online-status{
        margin-right: 5px;
    }
    .arrow{
        left: 31%;
    }
    .friend-request-action{
        float: right;
        margin-top: 10px !important;
        .contact-request-accept,
        .contact-request-reject {
            .button-constructor(4px, 12px, 3px);
            font-size: 10px !important;
            line-height: 5px !important;
            min-height: 0 !important;
            padding: 6px !important;
        }
    }
    .popover-head{
        position: relative;
    }
    .state-success {
        color: @successText;
        line-height: 18px;
    }
    .notification-setting-bt {
        display: none;
        text-decoration: none;
    }
}
