/*************************************************/
/* code sample */
@import (reference) "theme/variables.less";

.code-sample {
    padding: 14px;
    color: @text-color-light;
    background-color: @quaternary-color-dark;
    border-radius: 3px;
    font-weight: 700;
}
