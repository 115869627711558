/* Contact Support Banner */
@import "../../freemarket/theme/variables.less";
@import (reference) "libs/bootstrap.3.2.grid.less";

.fm-support {
    clear:both;
    padding: 18px 100px;
    background: @primary-color;
    .btn-support {
        float: right;
        text-transform: none;
    }
}

.fm-support-main {
    display: table;
    margin: 0 100px;
    background: url("/static/css/images/sites/support.png") no-repeat left center;
}

.fm-support-text {
    display: table-cell;
    width: 66%;
    padding-left: 140px;
    vertical-align: middle;
}

.fm-support-cta {
    display: table-cell;
    width: 33%;
    vertical-align: middle;
}

.fm-support-heading {
    padding-top: 12px;
    color: @site-text-color-alt;
    font-size: 24px;
}

.fm-support-heading-emphasis {
    font-style: italic;
    font-weight: 500;
}

.fm-support-copy {
    .primary-font;
    color: @site-text-color-alt;
    font-size: 16px;
    font-weight: 500;
}

/* Footer */

.fm-footer{
    .section-inner{
        padding:120px 0 180px;
    }
}

.footer-main{
    .make-row();
    padding-bottom:39px;
}

.footer-tagline{
    .make-xs-column(3);
    .primary-font;
    font-size:18px;
    color:@primary-color;
}

.footer-logo{
    .make-xs-column(12);
    padding: 0;
    margin-bottom:28px;

    .fm-logo{
        display:inline-block;
        width:171px;
        height:33px;
        .fm-logo-sprite;
        background-position:0 -40px;
    }
}

.footer-nav{
    .make-xs-column(9);
    margin-top:-20px;
}

.footer-links {
    float: right;
    overflow: hidden;
    padding: 0;
    width: 100%;
}
.footer-links-column {
    float: right;
    display: inline-block;
    min-width: 18%;
    line-height: 1.8em;
    color: @site-text-color-alt;
    &:first-child {
        min-width: 10%;
    }
    a {
        display: block;
        color: @quaternary-color-xxlight;
    }
}

.footer-nav-link{
    display:inline-block;
    vertical-align:bottom;
    margin-left:68px;
    .primary-font;
    font-size:18px;
    font-weight:700;
    color:@site-text-color-alt;
}

/*************************************************/
/* Footer corp */

.footer-corporate{
    .make-row();
    padding-top:24px;
    border-top:1px solid @quaternary-color-xxlight;
}

.footer-corporate-nav{
    .make-xs-column(6);
}

.footer-corporate-nav-link{
    display: inline-block;
    padding:0 8px;
    border-left-style: solid;
    border-left-width:1px;
    line-height: 9px;
    color:@quaternary-color-xxxlight;
    &:first-child{
        border-left-width:0;
        padding-left:0;
    };
}

.footer-logos{
    .make-xs-column(6);
}

.footer-terms{
    .make-xs-column(6);
    color:@quaternary-color-xxlight;
    text-align:right;
}
