@import (reference) "theme/variables.less";
// payment modal --- used to confirm the source of preferred payment, please remove after rebuild

.is-preferred, .change-preferred-method, .is-preferred-separator, .close-change-preferred-method, .open-change-preferred-method {
    float: right;
}

.is-preferred {
    color: @quinary-color;
    margin-right: 10px;
}

.change-preferred-method {
    margin-top: 25px;
}

.is-preferred-separator {
    margin-right: 10px;
}


/* Payment Options */

.payment-method-info {
    width: 402px;
    float: right;
    margin: @spacing-xsmall @spacing-xsmall 0;
}

 .payment-logo {
    background: url(/static/css/images/sprite-payments.png) no-repeat;
    display: inline-block;
    &.payment-logo-paypal {background-position: -105px 3px !important;height: 30px;width: 100px;}
    &.payment-logo-cc2 {background-position: -88px -1131px !important; height: 24px; width: 97px;}
    &.payment-logo-skrill {background-position: -140px -1230px !important; height: 26px; width: 122px;}
 }
