@import "../../../flux/modules/jquery-ui-custom/slider.less";

.ui-slider {
    border-color: @septenary-color-light;
    .ui-slider-handle {
        border-color: @septenary-color;
        &:before {
            background-color: @septenary-color-light;
        }
    }
    .ui-slider-range {
        background-color: @septenary-color-xxlight;
    }
}
