/**
 * Promotion flags
 *
 * Used to display what promotions a project/contest/service/site has applied
 *
 * Markup: promotion-flags.html
 *
 * Styleguide: Tags - Promotion Flags
 */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/*
 * 1. Hides prepaid promotional flags as it is no longer used. Remove this
 *    completely when it is removed from markup.
 */

.promotion-flags {
    display: inline-block;
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        color: @septenary-color-xxxlight;
        display: inline-block;
        padding: 5px 8px;
        position: relative;
        .font-size(11px);
        text-transform: uppercase;

        &:only-of-type {
            border-radius: 10px;
        }
    }

    &--small {
        li {
            .font-size(10px);
            line-height: 1.5;
            padding: 1px 8px;
        }
    }

    [class*="promotion"] {
        &:first-child {
            border-radius: 12px 0 0 12px;
        }

        &:last-child:not(:only-of-type) {
            border-radius: 0;
        }
    }

    .is-mobile-first & {
        @media (max-width: 940px) {
            [class*="promotion"] {
                border-radius: 12px;
                margin-bottom: 10px;
            }
        }
    }

    &.inverted {
        [class*="promotion"] {
            border-radius: 0;

            &:last-child:not(:only-of-type) {
                border-radius: 0 12px 12px 0;
            }

            &:only-child {
                border-radius: 0 12px 12px 0;
            }
        }
    }

    .promotion-qualified {
        background-color: @qualified-color;
    }

    .promotion-buy-now {
        background-color: @buy-now-color;
    }

    .promotion-hot-deal {
        background-color: @hot-deal-color;
    }

    .promotion-featured {
        background-color: @featured-color;
    }

    .promotion-urgent {
        background-color: @hot-deal-color;
    }

    .promotion-sealed {
        background-color: @sealed-color;
    }

    .promotion-fulltime {
        background-color: @fulltime-color;
    }

    .promotion-guaranteed {
        background-color: @prepaid-color;
    }

    .promotion-assisted {
        background-color: @assisted-color;
    }

    .promotion-highlight {
        background-color: @highlight-color;
    }

    .promotion-private {
        background-color: @private-color;
    }

    .promotion-nda {
        background-color: @nda-color;
    }

    .promotion-nca {
        background-color: @denary-color-dark;
    }

    .promotion-extend {
        background-color: @extend-color;
    }

    .promotion-local {
        background-color: @local-color;
    }

    .promotion-ip-contract {
        background-color: @ip-contract-color;
    }

    .promotion-top,
    .promotion-top-contest {
        background-color: @top-color;
    }

    .promotion-project-management {
        background-color: @management-color;
    }
}
