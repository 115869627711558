/*   user login module for use embeded within pages   */
// Should be made redundant by login modal
@import (reference) "theme/variables.less";

.user-login {
    display: table;
    width: 100%;
    border-bottom: 3px solid @border-color-light;
    padding-bottom: 40px;

    .user-login-user-type {
        margin: 0 0 0 0;
    }

    .user-login-primary-method {
        display: table-cell;
        vertical-align: top;
        width: 65%;
        padding-right: 40px;
    }

    .user-login-secondary-method {
        display: table-cell;
        vertical-align: top;
        padding: 0px 10px;
        text-align: center;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .user-login-fieldset {
        margin-bottom: 0;
    }

    .user-login-input-row {
        margin: 15px 0 0 0;
    }

    .user-login-input {
        display: inline-block;
        float: left;
        width: 48.2%;
        margin: 0 20px 0 0;
        &:last-child {
            margin-right: 0;
        }
    }

    .user-login-error {
        margin: 10px 0 0 0;
    }

    .user-login-facebook {
        margin-bottom: 8px;
    }

    .user-login-facebook-reference {
        display: block;
        margin-bottom: 10px;
    }

    .user-login-facebook {
        margin: 10px 0;
    }

    .loading-img {
        text-align: center;
        margin: 0 auto;
        display: block;
        padding-top: 30px;
        img {
            width: 30px;
        }
    }
}
.profile-pic-welcome {
    float: left;
    width: 100px;
    height: 100px;
    margin-right: 20px;
    padding: 2px;
    border: 1px solid @border-color-light;
    img {
        width: 100%;
        height: 100%;
    }
}
.logged-in-title {
    padding-top: 10px;
    margin-bottom: 5px;
}

.logged-in-pad {
    border-bottom: 3px solid @septenary-color-xxlight;
    padding-bottom: 40px;
    margin-bottom: 40px;
}
