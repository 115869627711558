/*
    Upgrade Listing

    Notes:
    1. Override high specificity of upgrade listing (nesting, !important)
*/
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.upgrade-listing {
    position: relative;

    &-freeRemaining {
        padding-bottom: @spacing-small;
    }
}

.upgrade-listing-message {
    .type(detail);
    background-color: @quinary-color;
    color: @text-color-light;
    padding: 7px 12px 8px;
    position: absolute;
    right: -6px;
    text-transform: uppercase;
    top: -16px;

    &::after {
        .triangle();
        border-color: @quinary-color-dark transparent transparent transparent;
        border-width: 7px 7px 0 0;
        bottom: 0;
        right: 0;
    }
}

.upgrade-listing-message-metric {
    .type(landing);
    display: inline-block;
    margin-bottom: -2px;
    vertical-align: bottom;
}

.upgrade-listing-message-extra {
    display: inline-block;
    margin-top: 10px;
}

.upgrade-listing-options {
    tbody {
        border-bottom: 1px solid @quaternary-color-xxxlight;

        tr {
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
            border-top: 1px solid @quaternary-color-xxxlight;

            &:hover {
                border: 1px solid @primary-color-xxlight !important;
                border-left: 1px solid transparent !important;
                border-right: 1px solid transparent !important;
            }
        }
    }

    &--alt {
        .upgrade-listing-upgrade {
            border-left: 1px solid @quaternary-color-xxxlight;
            border-right: 1px solid @quaternary-color-xxxlight;
        }
    }

    .status-label {
        white-space: nowrap;
    }

    .is-mobile-first & {
        @media (max-width: 490px) {
            tbody,
            tr,
            td {
                display: block;
                text-align: left;
                width: 100%;
            }

            .upgrade-option {
                padding-bottom: 20px;
            }

            .upgrade-listing-select-label {
                margin-bottom: 0;
            }

            .upgrade-listing-select,
            .upgrade-listing-type {
                display: inline-block;
                padding-bottom: 0 !important; /* 1 */
                width: auto;

                .promotion-tag {
                    margin-bottom: 0;
                }
            }

            .upgrade-listing-select {
                padding: 20px 5px 10px 10px !important; /* 1 */
            }

            .upgrade-listing-description {
                padding: 5px 0 10px 60px !important; /* 1 */
            }

            .upgrade-listing-price {
                padding: 0 0 0 60px;
                position: static;
            }
        }
    }
}

.upgrade-listing-type {
    vertical-align: top;
    width: 14%;
}

td.upgrade-listing-type {
    padding-top: 20px;
}

.upgrade-listing-upgrade {
    cursor: pointer;
    position: relative;

    .promotion-tag {
        .truncate(110px);
        height: auto;
        line-height: 1.2;
        margin-bottom: @spacing-xxsmall;
        padding: 5px 15px;
        word-wrap: break-word;
    }

    &--upgradeAlwaysSelected {
        cursor: default;
    }
}

.upgrade-listing-select {
    padding: 20px 16px 20px 0 !important;
    text-align: center;
    vertical-align: top;
}

.upgrade-listing-description {
    line-height: 20px;
    padding: 20px 15px !important;
    width: 66%;
}

.upgrade-listing-assisted {
    .type(landing);
    font-weight: bold;
    padding: 30px 20px 30px 0 !important;
    text-align: right;
    width: 16%;
}

td.upgrade-listing-price {
    padding-top: 20px;
    text-align: right;
    white-space: nowrap;
}

.upgrade-listing-price {
    .type(landing);
    font-weight: bold;
    position: relative;
    vertical-align: top;
}

.upgrade-listing-price-wrapper {
    white-space: nowrap;
}

.upgrade-listing-message-total {
    .type(heading2);
    font-weight: bold;
    margin-top: 20px;
    text-align: right;
}

.upgrade-listing-message-gst {
    display: block;
    text-align: right;
}

.upgrade-listing-select-label {
    display: inline;
    position: relative;
}

.upgrade-listing-more {
    display: block;
    margin-top: 13px;
}

.upgrade-listing-promo {
    .type(detail);
    background-color: @quinary-color-light;
    color: @text-color-light;
    display: inline-block;
    padding: 5px 8px;
    position: absolute;
    right: -7px;
    text-transform: uppercase;
    top: -12px;
    white-space: pre;

    strong {
        .type(heading3);
        display: inline-block;
        font-weight: 900;
        height: 22px;
        margin: -5px 4px;
        vertical-align: sub;
    }

    &::before {
        border-color: transparent transparent transparent @quinary-color-xxdark;
        border-style: solid;
        border-width: 0 0 7px 7px;
        bottom: -7px;
        content: "";
        display: inline-block;
        height: 0;
        position: absolute;
        right: 0;
        width: 7px;
    }
}

/***************************************/
/* Current Sub components*/
/***************************************/
.project-upgrade-banner {
    .type(detail);
    background: @quinary-color-light;
    color: @septenary-color-xxxlight;
    display: inline-block;
    padding: 5px 15px;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: -12px;
    z-index: 1000;

    &::after {
        border-color: @quinary-color-dark transparent transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0 0;
        bottom: -5px;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        right: 0;
        width: 0;
    }

}

.total-project-cost {
    float: right;
    margin-bottom: 60px;
}

.upgrade-listing-heading {
    .type(landing);
    font-weight: 900;
    line-height: 1;
    margin-bottom: 7px;
    position: relative;

    &::after {
        .absolute-cover();
        content: "";
        z-index: 10;
    }

    [class*="fl-icon-"] {
        .type(ui);
        margin-left: 5px;
        position: relative;
        z-index: 1;
    }
}

/* Upgrade savings */
/* Duplicate of `.UpgradeListing-newIpContract`
    (css/abtests/20150907/post-project.less) */
.upgrade-listing-saving {
    color: @quinary-color-xdark;

    &--successBundle {
        .type(heading2);
    }
}

.upgrade-listing-newNCA {
    color: @denary-color-dark;
}
