@import (reference) "../../theme/variables.less";
@import (reference) "../theme/variables.less";
@import (reference) "libs/bootstrap.3.2.grid.less";
.fm-steps{
    .make-row();
    list-style:none;

    li{
        .make-xs-column(4);
        padding: 0 20px;
        text-align: left;
    }

    p{
        margin-bottom:0;
    }

    [class*="fm-icon-"]{
        display:inline-block;
        margin-bottom:33px;
        color:@tertiary-color;
        font-size:45px;
    }

    .fm-steps-title{
        margin-bottom:20px;
        font-size:22px;
        font-weight:700;
        .primary-font;
        letter-spacing: -1px;
    }

    .fm-steps-subtitle{
        display:block;
        margin-bottom:14px;
        text-transform:uppercase;
        color:@quaternary-color-xxlight;
        .primary-font;
    }

    .fm-steps-copy{
        color:@quaternary-color-xlight;
        font-size:14px;
    }

    &.is-large{
        [class*="fm-icon-"]{
            font-size:100px;
        }
    }
}
