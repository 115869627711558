@import "libs/bootstrap.3.2.grid.less";
@import "../modules/steps.less";
@import "../../flux/modules/inputs.less";
@import "../../flux/modules/large-tabs.less";
@import "../../flux/modules/tables.less";
@import "listings-table.less";

 /****************************************************/
/* Input CTA section */

.input-cta{
    .btn{
        padding-left:60px;
        padding-right:60px;
    }

    input:focus{
        border-color:@primary-color;
    }

    .input-listing {
        font-family: "museo_sans", Arial, sans-serif;
        border-width: 1px;
    }
}

.input-cta-title{
    margin-bottom:45px;
    font-size:24px;
    font-weight:700;
    text-align:center;
    line-height:35px;
    letter-spacing: -1px;
}


 /****************************************************/
/* Hero section */

.hero-section{
    position:relative;

    .section-inner{
        clear:both;
    }

    .input-cta{
        margin-bottom:50px;
    }

    &:after{
        content:'';
        position:absolute;
        z-index:2;
        left:0;
        right:0;
        bottom:0;
        height:45px;
        background-color:@site-bg-color;
    }
}

.hero-bg{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:@septenary-color-xxxdark url("/static/css/images/sites/fm-hp-hero-bg.jpg") no-repeat top center;
}

.hero-title{
    .make-xs-column(12);
    margin: 92px 0 70px;
    padding: 0 150px;
    color: @septenary-color-xxxlight;
    text-align: center;
    font-size: 55px;
    line-height: 70px;
    font-weight: 700;
    letter-spacing: -2px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.hero-content{
    .make-row();
    position:relative;
    z-index:3;
    overflow:hidden;
    padding:50px 65px 30px;
    background:@septenary-color-xxxlight;
    text-align:center;
    border:1px solid @border-color-light;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}

.listings-section{
    .clearfix;
    padding:80px 0 180px;
}

.listings-title{
    font-size:36px;
    line-height:42px;
    text-align:center;
}

.listings-subtitle{
    display:block;
    margin-bottom:34px;
    color:@quaternary-color-xlight;
    text-align:center;
}


 /****************************************************/
/* Info section */

.info-hero-section{
    position:relative;
    height:301px;
    padding-top:138px;
    text-align:center;
    overflow:hidden;
    background:@hero-bg-color url("/static/css/images/sites/fm-info-banner.jpg") no-repeat top center;
}

.info-hero-title{
    position:relative;
    z-index:2;
    display:inline-block;
    margin:0;
    padding-left:90px;
    font-size:36px;
    color:@septenary-color-xxxlight;
    letter-spacing: -1px;
}

.info-hero-logo{
    position:absolute;
    left:0;
    top:-10px;
    display:inline-block;
    width:60px;
    height:60px;
    .fm-logo-sprite;
    background-position:0 -80px;
}

.info-section{
    .clearfix;
    padding:40px 0 0;

    .input-cta{
        .make-xs-column(10);
        .make-xs-column-offset(1);
        padding:50px 0 80px;
        border-top:1px solid @septenary-color-xxlight;
    }

    .tab-pane{
        padding:60px 0 0;
    }
}

.info-breakdown{
    .make-row();
    padding:0;
    margin:0;
    list-style:none;
    li{
        .make-xs-column(4);
        margin-bottom:60px;
        padding:0 4%;
        color:@quaternary-color-xlight;
    }

    [class*="fm-icon-"]{
        display:inline-block;
        margin-bottom:26px;
        color:@tertiary-color;
        font-size:50px;
    }
}

.info-breakdown-title{
    position:relative;
    min-height:73px;
    margin-bottom:25px;
    font-size:16px;
    text-transform:uppercase;
    color:@quaternary-color-dark;
    text-align:left;
    line-height:25px;
    letter-spacing: 1px;

    &:after{
        content:'';
        position:absolute;
        bottom:0;
        left:0;
        width:20px;
        height:2px;
        background-color:@quaternary-color-xlight;
    }
}

 /****************************************************/
/* Steps section */

.steps-section{
    padding:80px 0 115px;
    border:1px solid @border-color-light;
}

.steps-title{
    font-size:28px;
    margin-bottom:65px;
    text-align:center;
    letter-spacing: -1px;
}

 /****************************************************/
/* Testimonials section */

.testimonials-section{
    .clearfix;
    padding:120px 0 90px;
    background: @hero-bg-color url("/static/css/images/sites/pattern-darker.jpg");
    background-attachment: fixed;
    text-align:center;
    color: @site-text-color-alt;
}

.testimonials-title{
    .make-xs-column(8);
    .make-xs-column-offset(2);
    margin-bottom:50px;
    .primary-font;
    font-size:36px;
    line-height:50px;
    font-weight:700;
}

.testimonials-copy{
    position:relative;
    .make-xs-column(8);
    .make-xs-column-offset(2);
    margin-bottom:38px;
    padding-bottom:60px;
    color:@site-text-color-alt;
    line-height:25px;

    &:after{
        content:'';
        position:absolute;
        bottom:0;
        left:50%;
        margin-left:-15px;
        width:30px;
        height:4px;
        background-color:@quaternary-color-dark;
    }
}

.testimonials-user{
    .make-xs-column(4);
    .make-xs-column-offset(4);
}

.testimonials-avatar{
    display:inline-block;
    width:60px;
    height:60px;
    margin-bottom:30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.testimonials-username{
    .primary-font;
    display:block;
    margin-bottom:15px;
    font-size:22px;
    font-weight:700;
}

.testimonials-user-position{
    color:@site-text-color-alt;
}

 /****************************************************/
/* Input CTA section */

.input-cta-section{
    .clearfix;
    padding:150px 0;

    .input-cta{
        .make-xs-column(10);
        .make-xs-column-offset(1);
    }
}
