@import "../../flux/modules/section-title.less";
@import "../../flux/modules/social-media-links.less";
@import "../../flux/modules/discussion.less";

@import (reference) "libs/bootstrap.3.2.grid.less";
@import (reference) "../../libs/mixins.less";
@import (reference) "../theme/variables.less";

/* Animations */
@import "../../mixins/animated/pulse.less";
@import "../../mixins/animated/fadeInRight.less";
@import "../../mixins/animated/fadeInLeft.less";

    /*******************************************************/
    /*******************************************************/
    /** Sites view page **/

    .sites-view{
        padding: 50px 0 100px;
        .section-title {
            padding-left: 0;
            font-size: 16px !important; // overriding section-title.less
            border-bottom: 1px solid @quaternary-color;
        }
    }

    .sites-view-row{
        .make-row();
        margin: 0 auto !important;
    }

    .sites-view-statistics {
        margin-bottom: 50px !important;
    }

    .view-edit-btn{
        display:inline-block;
        background-color:transparent;
        border:none;
        padding:0 0 0 2px;
        font-size:13px;
        color:@quaternary-color;
        text-decoration: none;
        &:hover{
            .animated(.4s);
            .pulse;
            text-transform:none;
        }
    }

    /*******************************************************/
    /** intro section **/

    .sites-intro {
        padding: 90px 0 25px;
        background: @primary-color-light;
    }

    .sites-intro-bookmark{
        position:absolute;
        z-index:2;
        top:8px;
        left:-7px;
    }

    .sites-intro-text{
        margin: 0 10px;
        line-height: 1;
        .edit-form textarea {
            width: 450px;
        }
    }

    .sites-intro-main {
        display: block;
        vertical-align: top;
    }

    .sites-intro-title{
        display: inline-block;
        margin: 0 0 20px;
        padding-right:19px;
        line-height:32px;

        a {
            .dark-link-color;
            color: @site-text-color-alt;
            font-size: 32px;
            font-weight:700;
            word-break:break-all;
            vertical-align: middle;
            &:hover {
              color: @septenary-color-xxlight;
            }
        }
    }

    .sites-intro-title-featured {
        display: inline-block;
        margin-bottom: 16px;
        padding: 5px 10px;
        color: @septenary-color-xxxlight;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        vertical-align: middle;
        border: 2px solid @septenary-color-xxxlight;
        .border-radius(3px, 3px, 3px, 3px);
    }

    h2.sites-name {
        display: inline;
        font-size: 18px;
        font-weight: normal;
        font-style: italic;
        line-height: 1;
    }

    .sites-offers-more {
        display: block;
        margin-top: 38px;
        padding: 20px;
        background: darken(@septenary-color-xxlight, 2%);
        color: @quaternary-color-xxlight;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        &:hover {
            text-decoration: none;
            color: @quaternary-color-xlight;
        }
    }

    /*******************************************************/
    /** Sites summary **/

    .sites-summary {
        overflow: hidden;
        padding: 25px 0;
        background: @quaternary-color-xxxdark;

        .sites-view-handover-bt {
            float: right;
            margin: 20px 10px 0 0;
        }
    }

    .sites-summary-list {
        .make-xs-column(6);
        margin: 0;
        li {
            display: inline-block;
            list-style: none;
            margin-right: 30px;
            vertical-align: top;
        }
    }

    .sites-summary-list-item {
        display: block;
        margin-bottom: 20px;
        color: @septenary-color-light;
        font-size: 14px;
        font-style: italic;
    }

    .current-offer {
        clear: left;
        margin: 0;
        padding-top: 15px;
        font-size: 14px;
        color: @septenary-color-light;
        font-style: italic;

        p {
            margin: 0;
        }
    }

    .sites-summary-list-value {
        display: block;
        color: @site-text-color-alt;
        font-size: 26px;
        font-weight: bold;
        text-align:center;
    }

    .sites-summary-winning-img {
        overflow: hidden;
        display: inline-block;
        width: 32px;
        height: 32px;
        vertical-align: middle;
        .border-radius(100px, 100px, 100px, 100px);
        img{
            width:100%;
            height:100%;
        }
    }

    .sites-summary-winning {
        display: inline-block;
        color: @primary-color-xxxxlight;
        font-size: 14px;
        vertical-align: middle;
        &.top-bidder {
            color: @quinary-color-xlight;
        }
    }

    .sites-summary-list-action {
        .make-xs-column(6);
        margin: 16px auto;
        .fl-form {
            .btn {
                width: 125px;
                float: left;
                margin-left: 10px;
            }
            .input-group {
                margin-right: 10px;
                .add-on {
                    border: 0;
                    padding: 6px 2px;
                    background-color: #FFF;
                }
                input {
                    border: 0;
                }
            }

            .sites-view-bidding-submit {
                margin: 0 10px 0 0;
            }
        }
        .input-group {
            width: 300px !important;
            float: left;
        }
        &.is-buyer {
            margin: 0;
        }
        &.is-seller {
            .make-xs-column(4);
            float: right;
        }
        &.is-claimant {
            .make-xs-column(4);
            float: right;
        }
        .sites-bid-section-status {
            padding-bottom: 10px;

            .sites-bid-status-text {
                display: inline;
            }
        }
    }

    /*******************************************************/
    /** Sites Offers **/

    .sites-offers {
        overflow: hidden;
        padding: 25px 0 0 0;
        background: @septenary-color-xxlight;
        .section-title {
            border: none;
            text-align: center;
        }
    }

    .sites-view-bin {
        .make-xs-column(4);
        float: none;
        margin: 0 auto 40px auto;
        .sites-view-list {
            margin: 0;
            padding: 0;
            list-style: none;
            color: @site-text-color-alt;
            font-size: 14px;
            li {
                margin-bottom: 2px;
                padding: 10px 10px;
                background: @quaternary-color-xxlight;
                font-weight: bold;
                .border-radius(4px, 4px, 4px, 4px);
            }
            .list-bin-price {
                display: inline-block;
                width: 65px;
            }
            .list-bin-user {
                display: inline-block;
                width: 185px;
            }
            .list-bin-accept {
                float: right;
                color: @site-text-color-alt;
                font-size: 12px;
                line-height: 18px;
                text-transform: uppercase;
            }
        }
    }

    .sites-offers-more {
        display: block;
        padding: 20px;
        background: darken(@septenary-color-xxlight, 2%);
        color: @quaternary-color-xxlight;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        &:hover {
            text-decoration: none;
            color: @quaternary-color-xlight;
        }
    }

    /*******************************************************/
    /** Sites Description, Seller, etc. **/

    .sites-view-thumbnail {
        .make-xs-column(2);
    }

    .view-intro-image {
        overflow: hidden;
        .sites-spritemap;
        background-color:@quaternary-color-xxxxlight;
        background-position:10px -957px;

        img{
            width:100%;
            height:100%;
        }
    }

    .view-main-content {
        .make-xs-column(6);
    }

    .view-side-content {
        .make-xs-column(4);
        overflow: hidden;
    }

    .sites-user {
        position: relative;
        display: table;
        width: 100%;
        margin: 15px 0 30px 0;
    }

    .sites-user-img,
    .sites-attachment-img {
        display: table-cell;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        img {
            width: 100%;
            display: block;
        }
    }

    .sites-user-info{
        display:table-cell;
        padding-left: 15px;
        vertical-align:middle;
    }

    .sites-attachments-list {
        margin: 0;
        padding: 0;
        list-style: none;
        color: @quaternary-color-xlight;
        font-size: 14px;
        li {
            position: relative;
            display: table;
            margin: 15px 0;
            a {
                font-weight: bold;
            }
        }

        .attachment-label {
            display: block;
            margin-top: 8px;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
        }

        .fl-icon-file-alt {
            font-size: 30px;
            padding-left: 5px;
        }
    }

    .sites-attachment-info {
        display: table-cell;
        padding-left: 15px;
        vertical-align: middle;
    }

    .sites-view-financials,
    .sites-view-traffic {
        .make-xs-column(4);
    }

    .sites-view-pr,
    .sites-view-backlinks,
    .sites-view-pagecount,
    .sites-view-alexa,
    .sites-view-sem,
    .sites-view-registration {
        .make-xs-column(2);
        .list-value {
            display: block;
            margin: 15px 0 0;
            font-weight: bold;
        }
    }

    .sites-summary-upgrade,
    .sites-summary-claim,
    .sites-summary-edit,
    .sites-summary-remove {
        .make-xs-column(6);
        margin-bottom: 10px;
        .btn {
            width: 100%;
        }
    }

    .sites-summary-edit .btn {
        text-transform: uppercase;
    }

    .sites-view-list {
        margin-top: 20px;
        .list-item {
            float: left;
            clear: left;
            width: 130px;
        }
        .list-value {
            margin: 15px 0 15px 130px;
            padding: 0;
            font-weight: bold;
        }

        .sites-view-categories & {
            padding: 0;
            list-style: none;
        }

        a {
            font-weight: bold;
        }

    }

    /*******************************************************/
    /** Categories section **/

    .sites-view-category-parent{
        width: 100% !important;
    }

    .sites-view-category-children{
        width: 100%;
        padding: 0;
        margin: 15px 0;
        list-style: none;
    }

    /*******************************************************/
    /** traffic section **/

    .traffic-section, .revenue-section {
        position: relative;
        padding: 0 30px !important;
        margin-bottom: 40px !important;
        .section-title {
            margin-bottom: 30px;
            text-align: left;
        }
        &-content {
            .make-xs-column(8);
            padding: 0;
            width: 65%;
            margin-right: 1.66667%;
        }
        &-side-content {
            padding: 0;
            text-align: center;
            min-height: 350px;
            .section-title {
                margin-bottom: 20px !important;
                text-align: right;
                padding-top: 0;
            }
            .view-main-content {
                z-index: 2;
                &:nth-child(2) {
                    padding-right: 0;
                }
            }

            .sites-verify-ga {
                position: relative;
                z-index: 2;
                bottom: 153px;
                text-transform: initial;
                margin: 0 auto;
            }

            .btn-add-revenue {
                position: relative;
                z-index: 2;
                bottom: 125px;
                text-transform: initial;
                margin: 0 auto;
            }

            .traffic-count-value {
                font-size: 28px;
                line-height: 28px;
                font-weight: bolder;
                text-align: right;
                color: @quinary-color-dark;
                .amount-edit {
                    width: 150px;
                    text-align: right;
                }
            }
            .traffic-count-value:not(.traffic-text-color) {
                color: @septenary-color-dark;
            }

            .sites-ga-disconnect-btn {
                margin-top: 20px;
            }

            td.amount.inline-edit {
                text-align: right;
                padding-right: 10px;
            }

            .amount-edit {
                width: 75px;
                text-align: right;
                padding-right: 5px;
            }

            .month-edit {
              color: @quaternary-color-dark;
            }

            .action-items {
                margin-top: 15px;
                margin-right: 15px;
                text-align: right;
            }
        }
        .no-data-provided {
            position: relative;
            z-index: 2;
            top: 140px;
            padding: 12px 20px;
            background-color: @septenary-color-xxxdark;
            color: @septenary-color-xxxlight;
            font-weight: bold;
            font-size: 16px;
            width: 272px;
            display: block;
            text-align: center;
            margin: 0 auto;
        }
        .chart-canvas {
            width: 100%;
            height: 100%;
        }
        .chart-loading {
            display: block;
            margin: 0 auto;
            margin-top: 140px;
        }
        .traffic-table {
            width: 100%;
            margin: 0 10px;
            th {
                border-bottom: 1px solid @septenary-color-dark;
                padding-bottom: 15px !important;;

                &.number {
                    text-align: right;
                }
            }
            th, td {
                padding: 5px 10px;
                font-weight: bold;
            }
            td {
                text-align: center;
                &:first-child {
                    padding-left: 0;
                    text-align: left;
                    width: 38%;
                    font-weight: normal;
                }
            }

            th {
                font-weight: bold;
                text-align: right;
                &:first-child {
                    padding-left: 0;
                }
            }

            tbody {
                tr {
                    border-bottom: 1px dashed @septenary-color-light;

                    td {
                        padding: 10px;
                    }


                    &:last-child {
                        border: none;
                    }
                }

                td.number {
                    text-align: right;
                    color: @quinary-color-dark;
                }
            }
        }

        .dim-overlay {
            position: absolute;
            top: 40px;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(/static/css/images/sites/graph-image.png) 40px 20px no-repeat @septenary-color-xxxlight;
            background-size: 60%;
            opacity: .7;
        }

        .sites-ga-verify-alert {
            margin: 30px 0 0 20px;
        }

        .google-analytics-verified {
            margin-top: 20px;
            color: @quinary-color-dark;
        }
    }

    .revenue-section .sites-revenue-edit-icon {
      display: block;
      text-align: right;
      .revenue-edit-btn {
        border: none;
        display:inline-block;
        background-color:transparent;
        padding:0 0 0 2px;
        font-size:13px;
        color:@quaternary-color;
        text-decoration: none;
        position: absolute;
        top: 15px;
        right: 30px;
        &:hover{
          .animated(.4s);
          .pulse;
          text-transform:none;
        }
      }
    }

    /*******************************************************/
    /** discussion thread section **/

    .sites-view-discussion-board {
        .make-xs-column(8);

        .discussion-board-detail {
            float: right;
            font-weight: normal;
        }

        .discussion-post-message-input {
            resize: none;
        }

        .sites-view-discussion > .discussion-thread > .discussion-thread-message {
            &.has-replies > .discussion-thread-reveal-bt .discussion-thread-reveal-inner {
                display: inline;
            }

            .discussion-thread-reveal-bt .discussion-thread-reveal-inner {
                display: none;
            }
        }

    }

    .sites-view-discussion {
        margin-top: 15px;
    }

    /*******************************************************/
    /** Sites user component **/

    .sites-user-name{
        display:block;
    }

    .sites-user-status{
        display:block;
        color:@quaternary-color-light;
        font-weight:700;
    }

    .sites-user-flag{
        margin-right:4px;
        vertical-align:text-top;
    }

    .sites-user-contact{
        margin:0;
        padding:0;
        list-style:none;
        li{
            -ms-word-break: break-all;
            word-break: break-all;
            position:relative;
            margin-bottom:8px;
            padding-left:15px;
            &:before{
                content:'';
                position:absolute;
                left:0;
                top:3px;
                width:11px;
                height:11px;
                background:@quaternary-color-xxxlight;
                .border-radius(5px, 5px, 5px, 5px);
            }
        }
    }

    .sites-view{
        .upgrade-listing{
            margin-bottom:12px;
        }
    }

    .sites-view{

        .edit-form {
            input { width: 120px; }
            .action-items {
                margin: 10px 0;
                .loading { display: none; }
            }
            .error {
                display: none;
                margin: 0;
                font-size: 11px;
                color: @tertiary-color-xxdark;
            }
        }

        .sites-view-description {
            .sites-description {
                display: inline;
                white-space: pre-wrap;
            }
            textarea {
                resize: vertical;
                width: 580px;
                height: 80px;
            }
            .section-title{
                margin-bottom:12px;
            }
        }
    }

    .sites-verify-phone-modal{
        .modal-body{
            padding-left:55px;
            padding-right:35px;
        }
        .sites-verify-step-intro{
            padding-bottom:0;
            border-bottom:none;
        }

        .alert {
            margin-bottom: 20px;
        }
    }

    .sites-listing-type {
        margin: 0 10px 20px;
        color: @text-color-dark;
        font-size: 14px;
        font-style: italic;
    }

    .sites-view-intro{

        .edit-form {
            textarea {
                resize: vertical;
                width: 350px;
                height: 80px;
            }
        }
    }

    .sites-stats-error,
    .sites-pcb-deleted-error,
    .sites-pcb-error {
        color: #de4062;
    }

    .sites-pcb-deleted-info,
    .sites-pcb-error {
        margin-top: 10px;
    }

    .sites-pcb-inactive,
    .sites-pcb-deleted-info {
        color: @septenary-color-light;
        font-style: italic;
    }

    .sites-stats-error, .sites-stats-pending {
        margin-bottom: 40px;
    }

    .sites-extra-stats.loading {
        margin-bottom: 40px;
        text-align: center;
    }

    .sites-view-seller{
        .sites-view-statistics-list{
            margin-bottom:10px;
        }
    }

    .sites-view-new-seller{
        .sites-user-category{
            margin-top:20px;
        }
    }

    /*******************************************************/
    /** Bidding section **/

    .bidding{
        .primary-font;

        margin-bottom:40px;

        &.is-winning{
            .bidding-label-win{
                display:inline-block;
            }

            .bidding-state-msg{
                color:@tertiary-color;
            }

            .bidding-state{
                display:block;
            }
        }

        &.is-losing{
            .bidding-label-lose{
                display:inline-block;
            }

            .bidding-state-msg{
                color:@alert-color;
            }

            .bidding-state{
                display:block;
            }
        }
    }

    .bidding-financials{
        padding:30px 30px 15px;
        background-color:@septenary-color-xxxdark;
    }

    .bidding-title{
        margin-bottom:10px;
        color:@quaternary-color-xxlight;
        font-size:14px;
        text-transform:uppercase;
    }

    .bidding-price{
        color:@septenary-color-xxxlight;
        font-size:28px;
        font-weight:700;
    }

    .bidding-currency{
        margin-right:30px;
        text-transform:uppercase;
        color:@septenary-color-xxxlight;
        vertical-align:text-bottom;
        font-weight:700;
    }

    .bidding-reserve-msg{
        font-weight: 700;
        color:@primary-color-xxxxlight;
        &.reserve-met {
            color: @primary-color-light;
        }
    }

    .bidding-duration-icon{
        display:inline-block;
        margin-right:10px;
        margin-bottom:-1px;
        font-size:18px;
        vertical-align:bottom;
    }

    .bidding-options{
        padding:30px;
        border:1px solid @border-color-light;
        .sites-view-bidding-form {
            .loading {
                display: block;
                margin: 10px auto 0 auto;
            }
            .js-error-text {
                margin-top: 10px;
            }
        }
        .current-offer {
            margin-top: 10px;
            p {
                margin: 0;
            }
        }
    }

    .bidding-bid-bt{
        width:100%;
        margin-top:10px;
    }

    .bidding-buy-bt{
        width:100%;
        margin-top:10px;
    }

    .bidding-state{
        display:none;
        padding:30px;
        border:1px solid @border-color-light;
        background-color:@septenary-color-xxlight;
        color:@septenary-color-light;

        .bidding-price,
        .bidding-currency{
            color:@septenary-color-light;
        }
    }

    .bidding-state-title{
        margin-bottom:10px;
        font-size:14px;
        text-transform:uppercase;
    }

    .bidding-label-win,
    .bidding-label-lose{
        display:none;
        padding:6px 10px;
        color:@septenary-color-xxxlight;
        text-transform:uppercase;
        vertical-align:text-bottom;
    }

    .bidding-label-win{
        background-color:@tertiary-color;
    }

     .bidding-label-lose{
        background-color:@alert-color;
    }

    .bidding-state-msg{
        padding-top:18px;
        margin-top:20px;
        border-top:1px solid @septenary-color-xlight;
    }

    /*******************************************************/
    /** upgrade section **/

    .modal-upgrade-listing {
        overflow-y: hidden;
    }

    .sites-view-upgrade{
        .clearfix;
        margin-bottom:20px;

        .upgrade-listing-upgrade .upgrade-listing-type {
            width: 33%;
        }

        .upgrade-listing-options{
            tbody {
                tr {
                    border: 1px solid transparent;
                    &:last-child {
                        border-bottom: inherit;
                    }
                }
            }
            .promotion-tag {
                line-height: 25px;
                vertical-align: middle;
                padding: 2px 15px 0;
            }
            .upgrade-listing-select-label {
                line-height: 2.44;
                vertical-align: middle;
            }
        }
    }

    .sites-view-upgrade-title{
        margin-bottom:14px;
        font-size:20px;
        letter-spacing: normal;
    }

    .sites-view-finalise-upgrade-bt{
        float:right;
        margin-top:14px;
    }

    /*******************************************************/
    /** buyer info section **/

    .sites-view-buyers{
        margin-bottom:40px;
    }

    .sites-view-buyer-list{
        padding:0;
        margin:0;
    }

    .sites-view-buyer-info{
        max-width:100px;
        padding-right:10px;
        word-break:break-all;
    }

    .sites-view-buyer-accept-bt{
        float:right;
        margin-top:2px;
    }

    .sites-view-buyer:hover{
        .sites-view-buyer-accept-bt{
            .animated(.2s);
            .fadeInRight;
        }
    }

    /*******************************************************/
    /** description section **/

    .sites-view-similar-domains{
        margin:0;
        padding:0;
        list-style:none;
        border-bottom:1px solid @quaternary-color-xxxlight;
        .sites-view-similar-domain{
            padding:9px 0 9px 42px;
            margin:0;
            border-top:1px solid @quaternary-color-xxxlight;
            &:first-child{
                border-top:none;
            }
        }
    }

    .sites-view-similar-domain-url{
        display:block;
        margin-bottom:4px;
        font-weight:700;
    }

    .sites-view-similar-domain-price,
    .sites-view-similar-domain-bids,
    .sites-view-similar-domain-duration{
        display:inline-block;
        width:33%;
        font-weight:700;
    }

    .sites-view-similar-domain-duration,
    .sites-view-similar-domain-bids{
        text-align:right;
    }

    .sites-claim-listing-alert{
        background-color: #ddf9e6;
        border: 1px solid #9c8968 !important;
        position: relative;
        top: -40px;
        color: black;
        text-align: center;
        border-radius: 0;
        a{
            color: black;
            text-decoration: underline;
        }
    }

    /**
     * Fix for modifying the markup of discussion
     */
    .discussion-thread-content {
        &:hover {
            > .discussion-thread-message-remove-bt {
                .animated(.2s);
                .fadeInLeft;
            }
        }
    }

    .discussion-thread-content.sites-pcb-inactive {
        .discussion-thread-message-remove-bt {
            display: none;
        }
    }

    .discussion-thread-message {
        > .discussion-thread-content {
            > .discussion-thread-message-remove-bt {
                top:32px;
                left:-40px;
            }
        }
    }

    .discussion-thread-message-reply {
        > .discussion-thread-content {
            > .discussion-thread-message-remove-bt {
                top:24px;
            }
        }
    }
