@import (reference) "../../theme/variables.less";
@import (reference) "../theme/variables.less";
@import (reference) "../../libs/mixins.less";
@import (reference) "../../flux/modules/dropdown.less";
/*******************************************************/
    /*******************************************************/
    /** Sites dashboard page **/
    .sites-mysites-table {
        .sites-mysites-table-value,
        .sites-mysites-table-handover {
            white-space: nowrap;
        }
    }

    /*******************************************************/
    /*******************************************************/
    /** Sites my sites **/

    .sites-mysites-inner{
        .site-section-inner;
        position:relative;
        padding-top:55px;
        padding-bottom:100px;
    }

    .sites-mysites-explore-btn{
        float:right;
    }

    .bulk-alert {
        margin-bottom:25px;
    }

    .sites-mysites-title{
        display:inline-block;
        margin-bottom:28px;
        font-size:26px;
    }

    .sites-mysites-filters{
        margin-bottom:40px;
        width:100%;
        .button-group{
            font-size: 0;
            display:inline-block;
            margin-right:35px;
        }
    }

    .sites-mysites-search{
        float:right;
        margin-bottom:40px;
    }

    .sites-mysites-pagination{
        margin-bottom:20px;
        text-align:right;
        .pagination{
            display:inline-block;
        }
    }

    .sites-mysites-filter-results{
        &:before{
            content:"(";
        }
        &:after{
            content:")";
        }
    }

    .sites-mysites-listings-empty {
        clear: both;
        font-style: italic;
        margin-top: 60px;
        text-align: center;
    }

    .sites-mysites-listings-empty-icon {
        .sites-spritemap;
        display: block;
        margin: 0 auto;
        width: 126px;
        margin-bottom: 30px;
    }

    .listings-no-bids {
        .sites-mysites-listings-empty-icon {
            height: 114px;
            background-position:0 -526px;
        }
    }

    .listings-no-sites {
        .sites-mysites-listings-empty-icon {
            height: 148px;
            background-position:0 -378px;
        }
    }

    /*******************************************************/
    /** Sites mysites table **/

    .sites-mysites-table{
        .sites-mysites-table-info{
            width:450px;
        }
        tbody{
            .sites-mysites-table-favorite{
                background-color:@quaternary-color-xxxxlight;
                text-align:center;
            }
            tr:hover{
                .sites-mysites-table-favorite{
                    background-color:darken(@hover-bg-color, 5%);
                }
            }
        }
    }

    .sites-mysites-table-favorite{
        padding-left:5px;
        padding-right:5px;
        vertical-align:middle;
    }

    .sites-mysites-table-buy{
        .sites-mysites-table-value{
            color:@quinary-color;
        }
    }

    .sites-mysites-table-tags{
        display:inline-block;
        padding:0;
        margin:0;
        list-style:none;
        li{
            display:inline-block;
            padding:0;
            margin-right:3px;
        }
    }

    .sites-mysites-table-link{
        display:block;
        font-weight:700;
        font-size:16px;
    }

    .sites-mysites-table-description{
        display:block;
        margin-bottom:10px;
        word-break:break-all;
    }

    .sites-mysites-table-bid-info{
        text-transform:uppercase;
    }

    .sites-mysites-table-options-bt{
        position:relative;
    }

    .sites-mysites-table-options-dropdown{
        .dropdown-nav;
        width:150px;
        top:30px;
        right:0;
        a{
            .dropdown-nav-item;
        }
    }

    .sites-mysites-pagination {
        margin: 0;
        text-align: left;

        .sites-mysites-pagination-info,
        .sites-mysites-pagination-links {
            float: left;
            margin: 0;
            vertical-align: top;
            width: 50%;
        }

        .sites-mysites-pagination-info {
            margin-top: 18px;
            text-align: left;
        }

        .sites-mysites-pagination-links {
            text-align: right;
            ul {
                padding: 0;
            }
        }
    }
