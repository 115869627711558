@import (reference) "bootstrap/2.32/variables.less";
@import (reference) "bootstrap/2.32/mixins.less";
@import "bootstrap/2.32/navs.less";
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "libs/ceaser.custom.less";

/* mutator for bootstraps tabs to create a large, 2 tab system with arrows */
.large-tabs {
    overflow: hidden; // Prevents <a> background colour from having sharp corners
    margin: 0;
    padding: 0;
    border: 1px solid @border-color-light;
    border-radius: 10px;
    background-color: @septenary-color-xxlight;

    > li {
        display: inline-block;
        position: relative;
        width: 50%;
        margin: 0 !important;

        &.active {
            a {
                color: @septenary-color-xdark !important;
                background-color: @septenary-color-xxxlight !important;
            }

            &::before {
                position: absolute;
                z-index: 2;
                width: 0;
                height: 0;
                border-style: solid;
                content: "";
                transition: border 0.2s;
            }

            &:first-child::before {
                top: 0;
                right: -20px;
                border-width: 40px 0 40px 20px;
                border-color: transparent transparent transparent @septenary-color-xxxlight;
            }

            &:last-child::before {
                top: 0;
                left: -20px;
                border-width: 40px 20px 40px 0;
                border-color: transparent @septenary-color-xxxlight transparent transparent;
            }
        }
    }

    a {
        .easeOutQuad(.2s);
        .type(heading2) !important;
        display: table !important;
        width: 100%;
        height: 80px !important;
        margin: 0 !important;
        padding: 0 60px !important;
        border: none !important;
        border-radius: 0 !important;
        font-weight: bold !important;
        text-align: center;
        color: @septenary-color-xlight !important;
        transition: background 0.2s;

        &:hover {
            color: @septenary-color-xxxlight !important;
            background-color: @primary-color !important;
        }
    }

    .large-tabs-text {
        display: table-cell;
        line-height: 24px;
        letter-spacing: -1px;
        vertical-align: middle;
    }
}
