@import (reference) "../../theme/variables.less";
@import (reference) "../theme/variables.less";
@import "../../libs/ceaser.custom.less";

.fm-user-area{
    position:fixed;
    z-index:10;
    top:0;
    bottom:0;
    right:-@usernav-width;
    width:@usernav-width;
    padding:34px 50px 50px;
    background-color:@quaternary-color-xxdark;
    .easeOutQuad(@usernav-ani-time);
    .primary-font;

    .has-open-menu &{
        right:0;
    }
}

.user-area-close{
    margin-bottom:28px;
    text-align:right;

    [class*="fm-icon-"]{
        display:inline-block;
        font-size:30px;
        background:none;
        border:none;
        color:@septenary-color-xxxlight;
    }
}

 /***********************************************/
/* User details */

.user-details{
    padding:28px 0;
    margin-bottom:32px;
    border-top:1px solid @quaternary-color-xlight;
    border-bottom:1px solid @quaternary-color-xlight;

    .user-avatar{
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
    }
}

.user-intro{
    position:relative;
    margin-bottom:32px;
    padding-left:60px;
}

.user-intro-welcome{
    display:block;
    margin-bottom:2px;
    color:@quaternary-color-xlight;
}

.user-intro-name{
    font-size:20px;
    color:@septenary-color-xxxlight;
}

.user-settings-link,
.user-logout-link{
    display:inline-block;
    margin-right:30px;
    color:@quaternary-color-xlight;
    text-transform:uppercase;
    font-weight:700;

    [class*="fm-icon-"]{
        display:inline-block;
        margin-right:12px;
        vertical-align:top;
        line-height:16px;
    }
}

 /***********************************************/
/* User nav */


.user-nav-link{
    display:block;
    margin-bottom:26px;
    color:@septenary-color-xxxlight;
    font-size:16px;
    text-transform:uppercase;
}

.user-area-cta-btn{
    margin-bottom:38px;
}
