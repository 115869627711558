/**************************************************************/
/* CSS toggle switches */
/* taken from https://github.com/ghinda/css-toggle-switch */
/* original code below left intact and then overridden below */

@import (reference) "libs/mixins.less";
@import (reference) "theme/variables.less";

.layout (@position; @top; @right; @left; @display; @width; @zindex) {
  position: @position;
  top: @top;
  right: @right;
  left: @left;
  display: @display;
  width: @width;
  z-index: @zindex;
}

.slide-button-transition (@duration: .1s) {
  -webkit-transition: all @duration ease-out;
  -moz-transition: all @duration ease-out;
  transition: all @duration ease-out;
}

.switch-toggle a,
.switch-light span span {
  display: none;
}

@media only screen {

/* light switch */

  .switch-light {
    display: block;
    height: 30px;
    /* Outline the toggles when the inputs are focused */
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 100px;
    /* Position the label over all the elements, except the slide-button (<a>)
      Clicking anywhere on the label will change the switch-state */
    /* Don't hide the input from screen-readers and keyboard access */
    }
    .switch-light * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    .switch-light a {
      display: block;
      .slide-button-transition;
    }
    .switch-light label, .switch-light > span {
      line-height: 30px;
      vertical-align: middle;
    }
    .switch-light input:focus ~ a,
    .switch-light input:focus + label {
      outline: 1px dotted #888888;
    }
    .switch-light label {
      .layout (relative; static; static; static; block; 100%; 3);
    }
    .switch-light input {
      .layout (absolute; static; static; static; inherit; auto; 5);
      opacity: 0;
    }
    .switch-light input:checked ~ a {
        right: 0%;
    }
    .switch-light > span {
      .layout (absolute; static; static; -100px; inherit; 100%; auto);
      margin: 0;
      padding-right: 100px;
      text-align: left;
    }
    .switch-light > span span {
      .layout (absolute; 0; static; 0; block; 50%; 5);
      margin-left: 100px;
      text-align: center;
    }
    .switch-light > span span:last-child {
      left: 50%;
    }
    .switch-light a {
      .layout (absolute; 0; 50%; static; block; 50%; 4);
      height: 100%;
      padding: 0;
    }

/* toggle switch */
    .switch-toggle {
        display: block;
        height: 30px;
        /* Outline the toggles when the inputs are focused */
        position: relative;
        /* For callout panels in foundation*/
        padding: 0 !important;
        /* Generate styles for the multiple states */
    }

    .switch-toggle a {
        display: block;
       .slide-button-transition;
      }

    .switch-toggle label,
    .switch-toggle > span {
        line-height: 30px;
        vertical-align: middle;
    }

    .switch-toggle input:focus ~ a,
    .switch-toggle input:focus + label {
        outline: 1px dotted #888888;
    }

    .switch-toggle input {
        position: absolute;
        opacity: 0;
    }

    .switch-toggle input + label {
        .layout (relative; static; static; static; inherit; 50%; 2);
        float: left;
        height: 100%;
         margin: 0;
        text-align: center;
    }

    .switch-toggle a {
        .layout (absolute; 0; static; 0; inherit; 50%; 1);
        padding: 0;
        height: 100%;
    }
    /* button in the 'on' state */
    .switch-toggle input:last-of-type:checked ~ a {
        left: 50%;
      }
}
/* Bugfix for older Webkit, including mobile Webkit. Adapted from
 * http://css-tricks.com/webkit-sibling-bug/
 */
@media only screen and (-webkit-max-device-pixel-ratio: 2) and (max-device-width: 1280px) {
    .switch-light,
    .switch-toggle {
        -webkit-animation: webkitSiblingBugfix infinite 1s;
    }
}

@-webkit-keyframes webkitSiblingBugfix {
    from {
        -webkit-transform: translate3d(0, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
    }
}


/**************************************************************/
/* FL overrides */
/* 1. Done using :before so we can place it inside our standard buttons and not have it increase the height */

.switch-toggle,
.switch-light {
  position: relative;
    display: inline-block;
    width: 40px;
    height: 18px;
  text-transform: capitalise !important;
}

.switch-toggle-bg {
  .transition(.2s, ease-out);
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  padding: 0 !important;
  &:before { /* 1. */
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    right: 0;
    background: @quaternary-color-xxdark;
    border-radius: 10px;
  }
}

.switch-light {
  margin-left: 0;
}

.switch-toggle-on,
.switch-toggle-off {
    position: relative;
    z-index: 3;
    cursor: pointer;
    outline: none !important;
}

.switch-toggle-marker {
    z-index: 2 !important;
    background-color: @quaternary-color-xxxxlight;
    border-radius: 50%;
    top: 2px !important;
    margin-left: 3px;
    width: 14px !important;
    height: 14px !important;
    outline: none !important;
    [class*="fl-icon-"],
  [class*="fm-icon-"] {
    display: inline-block;
    margin-top: 2px;
    margin-left: 2px;
        opacity: 0;
        font-size: 10px;
        color: @positive-color;
    }
}

.switch-light {
  .switch-toggle-marker {
    margin-right: 3px;
  }
  input:checked+.switch-toggle-bg:before {
      background: @positive-color;
  }
}

.switch-light input:checked ~ a {
  margin-right: 3px;
}

.switch-light input:last-of-type:checked ~ a {
    [class*="fl-icon-"],
  [class*="fm-icon-"] {
        opacity: 1;
        .transition(.2s, ease-out);
    }
}
