/**
 * Pseudo select
 *
 * The standard select element
 *
 * .pseudo-select - Default button
 * .small-pseudo-select - Small variation
 * .large-pseudo-select    - Large variation
 * .xlarge-pseudo-select - Extra Large variation
 *
 * Markup: default-pseudo-select.html
 *
 * Styleguide: Forms - Elements - Pseudo Select
 */

/**
 * Pseudo select /w checkbox
 *
 * The standard select element
 *
 * .pseudo-select - Default button
 *
 * Markup: default-pseudo-select-with-checkbox.html
 *
 * Styleguide: Forms - Elements - Pseudo Select with Checkbox
 */

@import (reference) "dropdown.less";
@import (reference) "inputs.less";

.select-arrow {
    background-image: url(/static/css/images/icons/pseudo-select.svg);
    background-repeat: no-repeat;
}

.pseudo-select-mixin {
    .select-arrow;
    display: block;
    position: relative;
    width: 100%;
    text-align: left;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    -o-user-select: none;
    user-select: none;
    text-overflow: ellipsis;
    cursor: pointer;
}

.pseudo-select-value {
    display: block;
    width: 100%;
    line-height: 30px;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@pseudo-select-options-height: 300px;
@pseudo-select-options-buttons-height: 50px;

.pseudo-select-options {
    left: -1px;
    .dropdown-nav;
    display: block;
    max-height: @pseudo-select-options-height;
    .border-radius(0, @form-field-border-radius, @form-field-border-radius, 0);
    a {
        .dropdown-nav-item;
    }

    &.has-checkboxes {
        width: 100%;
        padding: 0;
        background: @quaternary-color-xxxxxlight;
        border: 1px solid @border-color-dark;
        overflow-y: hidden !important;

        ul {
            overflow-y: scroll;
            overflow-x: hidden;
            list-style: none;
            max-height: @pseudo-select-options-height;
            padding: 20px;
            margin: 0;
        }
        li {
            display: inline-block;
            width: 100%;
            margin-bottom: 10px;
            vertical-align: top;
        }

        input {
            margin-right: 5px;
        }

        &.has-checkbox-btns {
            padding: 0;
            ul {
                max-height: @pseudo-select-options-height - @pseudo-select-options-buttons-height;
            }
        }
    }
}

.pseudo-select-btns {
    height: @pseudo-select-options-buttons-height;
    padding: 7px;
    background: @quaternary-color-xxxlight;
    text-align: right;
    border-top: 1px solid @quaternary-color-xxlight;
}

/* psuedo select sizes */

.small-pseudo-select {
    .small-input;
    .pseudo-select-mixin;
    padding-right: 27px !important;
    background-size: 20px;
    background-position: 100% 12px;
    .pseudo-select-options {
        top: 30px;
        max-height: 300px;
        overflow-y: auto;
        &:before, &:after {
           .box-shadow(none);
        }
        a {
            font-size: @small-input-font-size;
        }
    }
}

.large-pseudo-select {
    .large-input;
    .pseudo-select-mixin;
    padding-right: 34px !important;
    background-size: 26px;
    background-position: 100% 19px;
    .pseudo-select-options {
        overflow-y: auto;
        &:before, &:after {
           .box-shadow(none);
        }
        top: 44px;
        a {
            font-size: @large-input-font-size;
        }
    }
}

.xlarge-pseudo-select {
    .xlarge-input;
    .pseudo-select-mixin;
    padding-right: 42px !important;
    background-size: 32px;
    background-position: 100% 23px;
    .pseudo-select-options {
        top: 54px;
        overflow-y: auto;
        &:before, &:after {
           .box-shadow(none);
        }

        a {
            font-size: @xlarge-input-font-size;
        }
    }
}

.pseudo-select {
    .default-input;
    .pseudo-select-mixin;
    padding-right: 30px !important;
    background-size: 22px;
    background-position: 100% 16px;
    .pseudo-select-options {
        overflow-y: auto;
        &:before, &:after {
           .box-shadow(none);
        }
        top: 38px;
    }
    .pseudo-select-value {
       line-height: 38px;
    }
}
