@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.bookmark-tag {
    position: relative;
    display: inline-block;
    min-height: 21px;
    padding: 5px 9px;
    text-transform: uppercase;
    .type(detail);
    font-weight: 700;
    &:before,
    &:after {
        .triangle;
        right: -4px;
        z-index: 2;
    }
    &:before {
        border-width: 11px 4px 0 0;
    }
    &:after {
        border-width: 11px 0 0 4px;
    }
}

.bookmark-tag-inner {
    &:before {
        .triangle;
        position: absolute;
        bottom: -7px;
        left: 0;
        border-width: 0 7px 7px 0;
    }
}

.bookmark-theme-constructor(@bookmark-color) {
    background-color: @bookmark-color;
    color: @text-color-light;
    &:before {
        top: 0;
        border-color: @bookmark-color transparent transparent transparent;
    }
    &:after {
        bottom: 0;
        border-color: transparent transparent transparent @bookmark-color;
    }
}

.featured-bookmark {
    .bookmark-theme-constructor(@featured-color);
    .bookmark-tag-inner {
        &:before {
            border-color: transparent darken(@featured-color, 10%) transparent transparent;
        }
    }
}

.urgent-bookmark {
    .bookmark-theme-constructor(@urgent-color);
    .bookmark-tag-inner {
        &:before {
            border-color: transparent darken(@urgent-color, 10%) transparent transparent;
        }
    }
}

.assisted-bookmark {
    .bookmark-theme-constructor(@assisted-color);
    .bookmark-tag-inner {
        &:before {
            border-color: transparent darken(@assisted-color, 10%) transparent transparent;
        }
    }
}
