@import "listings-table.less";

/*******************************************************/
/*******************************************************/
/** Sites search page **/

.search-intro{
    .section-inner{
        position:relative;
        padding-top:50px;
    }
}

.search-table-heading{
    display:inline-block;
    margin-bottom: 3px !important;
}

.search-sell-btn {
    float:right;
    margin-top:10px;
}

.search-table-subheading{
    display: block;
    font-size: 18px;
    margin-bottom:35px;
}

.search-table{
    overflow: hidden;
    padding-bottom:100px;
}

.sites-search-table-errors {
    text-align: center;
    margin-bottom: 20px;
    color: #de4062;
}
