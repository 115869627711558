 /*************************************************/
/* section title with border underneath and icon */

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/* 1. Neutralise line-height differences between webkit and moz/ie */

.section-title {
    position: relative;
    .type(heading3)!important;
    margin-bottom: 0;
    padding: 10px 0 15px 37px;
    letter-spacing: normal;
    line-height: 1;
    @media screen and (-webkit-min-device-pixel-ratio:0) { /* 1. */
        line-height: .9;
    }
    border-width: 0;
    border-bottom: 3px solid @quaternary-color;
    color: @quaternary-color;

    [class*="fl-icon-"],
    [class*="fm-icon-"] {
        position: absolute;
        left: 0;
        right: 0;
        display: inline-block;
        margin: -3px 12px 0 0;
        vertical-align: top;
        .type(heading2);
        color: @quaternary-color;
    }

    [class*="flicon-"] {
        position: absolute;
        left: 0;
        top: 6px;
        width: 25px;
        height: 25px;
        fill: @quaternary-color;
    }
}
