@import "../../flux/modules/buttons.less";

.btn{
    text-transform:uppercase;
}

/* skin color overrides for Freemarket */

.primary-bt-skin{
    .button-skin-constructor(
        @primary-color, // background color
        @primary-color-light, // background hover color
        @primary-color, // border color
        @primary-color-light,  // border color hover
        @text-color-light, // text color
        @text-color-light,  // text color hover
        @primary-color-xxdark, // text shadow color
        bold, // text weight
        fade(@septenary-color-xxxlight, 20%), // shadow color
        fade(@septenary-color-xxxlight, 20%) // shadow hover color
    );
}

.secondary-bt-skin{
    .button-skin-constructor(
        @tertiary-color,  // background color
        @tertiary-color-light, // background hover color
        @tertiary-color-dark,  // border color
        @tertiary-color-dark,   // border color hover
        @text-color-light,  // text color
        @text-color-light,   // text color hover
        @tertiary-color-dark,  // text shadow color
        bold,  // text weight
        fade(@septenary-color-xxxlight, 20%),  // shadow color
        fade(@septenary-color-xxxlight, 20%) // shadow hover color
        ) ;
}
