@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

#toast-container {
    .toast {
        .box-shadow(~"0 0 0 transparent") !important;
        .transition(0.2s, ease-out);
        background: @quaternary-color-dark;
        color: @quaternary-color-xxlight;
        margin: 2px 0 0;
        min-height: 60px;
        padding: 0px 6px 0px 0px;
        position: relative;
        width: 400px;

        a {
            color: @quaternary-color-xxlight;
        }

        &[class*="fl-icon-"] {
            color: @quaternary-color-xlight;
        }

        &:hover {
            background-color: @quaternary-color-dark;
        }
    }

    p {
        line-height: 18px;
        margin: 0;
    }

    > .toast-info,
    > .toast-info-local {
        .rounded(5px);
        background-color: fade(@quaternary-color-dark, 95%);
        background-image: none !important;
        border: none;
        display: table;
        opacity: 1;
        overflow: hidden;
    }

    > .toast-info-local {
        background-color: fade(@primary-color-xxdark, 95%);

        &:hover {
            background-color: @primary-color-xxdark;
        }
    }

    .toast-message,
    .toast-message-local {
        .type(detail);
        border-left-color: fade(@quaternary-color, 90%);
        border-left-style: solid;
        border-left-width: 56px;
        color: @septenary-color-xxlight;
        display: block;
        height: 100%;
        min-height: 58px;
        padding: 10px 15px 10px 10px;

        .read-icon,
        .unread-icon {
            display: none;
        }
    }

    .toast-message-local {
        border-left-color: fade(@primary-color-xxxdark, 95%);
    }

    .toast-message-recruiter {
        display: flex;

        .toast-recruiter-icon {
            cursor: auto;
            margin: 0 10px;

            figure {
                padding: 5px;
            }
        }

        .toast-recruiter-content {
            cursor: auto;
            margin: 10px 15px 10px 0;

            .toast-username,
            .toast-agent-message {
                margin-bottom: 8px;
            }

            .toast-project {
                font-weight: bold;
                margin-bottom: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 350px;
            }

            .place-bid {
                color: #F7F7F7;
                margin-right: 15px;
            }

            .agent-chat {
                font-size: 0.9em;
            }
        }
    }

    .close {
        color: @quaternary-color-xxxlight;
        position: absolute;
        right: 7px;
        text-shadow: none;
        top: 5px;
    }

    .profile-img { /* old notifcation styles to be deleted once the HTML from the old site is deleted */
        height: 38px;
        left: 9px;
        position: absolute;
        width: 38px;
    }

    .toast-project-icon {
        font-size: 40px;
        left: 15px;
        position: absolute;
        top: 12px;
    }

    .toast-contest-icon {
        font-size: 29px;
        left: 15px;
        position: absolute;
        top: 12px;
    }

    .toast-recruiter-project-icon {
        display: inline-block;
        fill: #FFF;
        left: 15px;
        position: absolute;
        top: 12px;
        width: 34px;

        .Icon-image {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .toast-project-title {
        margin-bottom: 4px;
    }

    .toast-project-skills {
        .type(detail);
        display: inline-block;
        list-style-type: none;
        margin: 0 0 5px;
        padding: 0;

        li {
            display: inline-block;
        }
    }

    .toast-project-description {
        margin-bottom: 5px;
        max-height: 60px;
    }

    .toast-project-price {
        display: block;
    }
}
