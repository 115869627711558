@import (reference) "../../theme/variables.less";
@import (reference) "../theme/variables.less";
/*     Simple tabs: Used for navigation for browse tables */

.simple-tabs {
    margin-top: 0;
    margin-bottom: 25px;
    .primary-font;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid @septenary-color-xlight;

    > li {
        width: 21%;

        > a {
            margin-bottom: -3px;
            padding: 15px 10px;
            text-align: center;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 4px solid transparent;
            transition: color 0.2s, border 0.2s;
            &:hover {
                background: transparent;
                color: @septenary-color-light;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 4px solid @septenary-color-light;
            }
        }

        > .sites-filters {
            width: auto; // overriding .sites-filters
            float: none; // overriding .sites-filters
            margin-bottom: -3px;
            text-align: center;
            border: 1px solid @septenary-color-xlight;
            border-bottom: none;
            transition: color 0.2s, border 0.2s;

            .pseudo-select {
                .primary-font;
                background-position: 100% 20px;
                margin-bottom: 1px;
                padding: 15px 10px;
                border: none;
            }

            .pseudo-select-options {
                width: 131px;
                top: 46px;
                left: -13px;
            }

        }

    }

    > .pagination-select {
        width: 16%;
        &:hover {
            cursor: pointer;
        }
    }

    > .active {

        > a {
            color: @primary-color;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 4px solid @primary-color;
            &:hover {
                color: @primary-color;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 4px solid @primary-color;
            }
        }

    }

    .table-header-options {
        width: auto;
    }

    .table-header-pagination {
        margin-right: 0;
    }

}
