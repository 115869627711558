@import "../../flux/modules/upgrade-listing.less";
@import "../../flux/modules/promotion-tags.less";
@import "../../flux/legacy/user-login-embeded.less";

@import (reference) "../../libs/mixins.less";

 /*******************************************************/
    /*******************************************************/
    /** Sites Post form **/

    .sites-post-hero{
        position:relative;
        z-index:2;
        height:215px;
        background:url("/static/css/images/sites/sites-landing-hero-bg.png") no-repeat center 0;
        background-size: cover;
    }
    @media all and (min-width: 1920px){
        .sites-post-hero{
            background-size:100%;
        }
    }

    .sites-post-hero-inner{
        .site-section-inner;
        padding-top:70px;
        color:@septenary-color-xxxlight;
    }

    .sites-post-hero-title{
        font-size:55px;
        margin-bottom:10px;
    }

    .sites-post-hero-subtitle{
        margin-bottom:42px;
        font-size:26px;
    }

    .sites-post-form-inner{

        .site-section-inner;
        padding:40px 0 100px 0;
        min-width: 1200px;

        .large-form.fl-form > ol > li:before {
            line-height:34px;
        }
    }

    /*******************************************************/
    /** Sites Post form **/

    .sites-sell-form{
        display:inline-block;
        width:70%;

        > legend{
            display:none;
        }

        .google-verify-bt{
            font-size:15px;
        }

        .form-error {
            margin-bottom: 22px;
        }

        .form-step {
            position: relative;
        }

        input[type='number'] {
            -moz-appearance: textfield;
        }

        textarea {
            resize: none;
        }

        label {
            white-space: nowrap;
        }

        .help-inline {
            font-size: 11px;
            position: absolute;
            bottom: -10px;
            left: 0;
            line-height: 1px;
            color: #555;
        }

        .sites-upgrades-error {
            color: #de4062;
            margin-bottom: 20px;
        }

        .form-invalid-icon {
            margin-left: 4px;
        }

        .hidden-files {
            display: none;
        }

        .fm-listing-uploader {
            background-color: #F9F9F9;
            padding: 20px 0 0 20px;

            .file-upload-hint-text {
                line-height: 28px;
            }

            .fileUploadError {
                display: none;
            }
        }

        .attached-files {
            .file-list {
                list-style-type: none;
            }

            .delete {
                cursor: pointer;
            }
        }

        .add-domains {
            .domains {
                margin-bottom: 5px;
            }
        }

        .bulk-listings-content {
            display: none;
            margin-top: 30px;
            .currency {
                font-size: 15px;
                padding: 0 0 0 5px;
            }
        }

        .bulk-change-div {
            height: 92px;
            overflow: hidden;
        }

        .bulk-change-desc {
            float: left;
            display: inline-block;
            height: 92px;
            line-height: 92px;
        }

        .bulk-change-inner-div {
            border: 1px solid @border-color-light;
            padding: 10px;
            width: 659px;
            float: right;
        }

        td.upgrade-listing-type {
            width: 18%;
            .fl-icon-star-full,
            .fl-icon-marker,
            .fl-icon-lock2 {
                margin-top: 0;
                vertical-align: middle;
            }
        }

        td.upgrade-listing-type,
        td.upgrade-listing-price {
            padding-top: 20px !important;
        }

        .upgrade-listing-options tbody tr {
            border-left: 1px solid #FFF !important;
            border-right: 1px solid #FFF !important;
        }


    }

    .form-fieldset-help{
        width:265px;
    }

    .form-fieldset-help-title{
        display:block;
        margin-bottom:1em;
    }

    .form-fieldset-help-lists{
        padding-left:15px;
        margin-top:0.6em;
    }

    .sites-sell-form-category,
    .sites-sell-form-subcategory{
        display:inline-block;
        vertical-align:top;
        width:50%;
        padding-left:20px;
    }

    .sites-sell-form-duration,
    .sites-sell-form-currency,
    .sites-sell-form-appraisal,
    .sites-sell-form-start-bid,
    .sites-sell-form-asking,
    .sites-sell-form-reserve,
    .sites-sell-form-buy-now,
    .sites-sell-form-visitors,
    .sites-sell-form-views,
    .sites-sell-form-verify,
    .sites-sell-form-revenue,
    .sites-sell-form-expenses{
        display:inline-block;
        vertical-align:top;
        width:33%;
        padding-left:20px;
    }

    .sites-sell-form-category,
    .sites-sell-form-duration,
    .sites-sell-form-start-bid,
    .sites-sell-form-asking,
    .sites-sell-form-visitors,
    .sites-sell-form-revenue,
    .sites-sell-form-offers-only-currency{
        padding-left:0;
    }

    /*******************************************************/
    /** Sites Post side column **/

    .sites-post-form-side-column{
        display:inline-block;
        vertical-align:top;
        width:270px;
        padding-left:50px;
    }

    .sites-post-form-info{
        position:relative;
        z-index:3;
        margin:0 0 14px;
        text-align:center;
    }

    .sites-post-form-info-title{
        margin-bottom:0;
        padding:16px;
        font-size:24px;
        color:@text-color-light;
        background-color:@quinary-color-dark;
    }

    .sites-post-form-info-content{
        display:block;
        padding:12px;
        color:@text-color-light;
        background-color:@quinary-color-xlight;
    }

    .sites-post-form-ad{
        border-bottom:2px solid @success-notify-color;
    }

    .sites-post-form-ad-img{
        margin-bottom:18px;
    }

    .sites-post-form-ad-title{
        margin-bottom:18px;
        font-size:14px;
        font-weight:700;
    }

    .sites-sell-form-bulk {
        .whois-guard-warning {
            margin-bottom: 30px;
        }
        .error-list {
            list-style: none;
            margin-bottom: 20px;
        }
        .domains-form-step {
            margin-bottom: 20px;
        }
        input.invalid-bulk-field {
            color: @secondary-color-xdark;
            border-color: @secondary-color-xdark;
        }
    }


    /*******************************************************/
    /** Sites Post login form **/

    // To be removed with implementation of login modal.
    // Or once the new modular in-page login is built, this can be replaced with that.

    .sites-login {
        margin-top: 30px;

        .sites-post-form-alert {
            display: block;
            margin-bottom: 30px;
            width: 100%;
            p {
                margin-top: 0;
            }
        }
    }

    .sites-login-inner {
        .site-section-inner;
        padding: 0;
    }

    .user-login-secondary-method {
        visibility: hidden;
    }

    .user-login {
        background-color: lighten(@septenary-color-xxlight, 5%);
        border: 1px solid @border-color-light;
        border-radius: 4px;
        padding: 20px;
        .control-group.error input {
            color: @alert-color;
            border-color: @alert-color;
        }

        .help-inline {
            display: inline-block;
            padding-top: 5px;
            color: @alert-color;
        }
        ol {
            padding-left: 0;
        }
    }
