@import (reference) "theme/variables.less";
@import "js-plugins/jquery-ui/slider.less";
@import (reference) "flux/modules/fl-slider.less";

.ui-slider {
    .slider-range-mixin;

    .ui-slider-range {
        .fl-slider-bar;
    }

    .ui-slider-handle {
        .fl-slider-handle;
        &:nth-child(1) {
            margin-left: -6px;
        }
        &:nth-child(2) {
            margin-left: 0px;
        }
        &:nth-child(3) {
            margin-left: -12px;
        }
    }
}
