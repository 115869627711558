@import (reference) "theme/variables.less";

.fl-slider {
    display: inline-block;
    position: relative;
    width: 100%;
}

.slider-range-mixin {
    height: 4px;
    border-radius: 5px;
    background: @quaternary-color-xxlight;
}

.fl-slider-range,
.slider-range {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .slider-range-mixin;
}

.fl-slider-bar-mixin {
    border-radius: 5px;
    background: @primary-color;
}

.fl-slider-bar,
.slider-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    .fl-slider-bar-mixin;
}

.fl-slider-handle,
.slider-handle {
    display: inline-block;
    position: absolute;
    top: -7px;
    z-index: 3;
    width: 17px;
    height: 17px;
    border: 2px solid @primary-color;
    border-radius: 50%;
    background: @septenary-color-xxxlight;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 0 5px fade(@primary-color, 20%);
        transition: 0.25s box-shadow ease-out;
    }
}
