@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/* Animations */
@import "mixins/animated/fadeInLeft.less";
@import "mixins/animated/bounce.less";

.discussion {
    p {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: @spacing-small - @spacing-xxsmall;
    }
}

.discussion-avatar {
    height: 64px;
    left: 0;
    margin-bottom: @spacing-small - @spacing-xxsmall;
    position: absolute;
    top: 0;
    width: 64px;
}

/*******************************************************/
/** discussion post reply **/

.discussion-post {
    .type(chat);
    margin-bottom: 30px;
    min-height: 64px;
    padding-left: 76px;
    position: relative;
}

.discussion-post-message-input {
    .border-radius(3px, 3px, 3px, 3px);
    border-color: @field-border-color;
    max-width: 100%;
    min-height: 64px;
    padding: 11px 14px;
    width: 100%;
}

.discussion-post-message-btn,
.discussion-post-reply-btn {
    float: right;
    margin: @spacing-xxsmall 0 0;
}

.discussion-avatar > .profile-img {
    margin: 0;
}

.discussion-thread-message {
    .discussion-post {
        margin: 10px 0;
        min-height: 48px;
        padding-left: 60px;

        .discussion-avatar {
            height: 48px;
            margin-right: 0;
            top: 0;
            width: 48px;
        }
    }

    > .reply-post {
        margin-bottom: 40px;
    }

    .discussion-post-message-input {
        height: 48px;
        min-height: 48px;
    }
}

.discussion-thread-message-replies .discussion-post .discussion-avatar {
    left: 0;
}
/*******************************************************/
/** discussion thread **/

.discussion-thread {
    .discussion-avatar {
        top: 15px;
    }
}

.discussion-thread,
.discussion-thread-message-replies {
    list-style: none;
    padding: 0;
    position: relative;
}

.discussion-thread-message,
.discussion-thread-message-reply {
    padding-bottom: 15px;
    padding-top: 15px;
    position: relative;

    &:hover {
        > .discussion-thread-message-remove-bt {
            .animated(.2s);
            .fadeInLeft;
        }
    }
}

.discussion-thread-message {
    padding-left: 76px;

    /* reply line */
    &.has-replies {
        &::before {
            background: @quaternary-color-xxxlight;
            bottom: 50%;
            content: "";
            left: 32px;
            position: absolute;
            top: 89px;
            width: 2px;
        }
    }

    > .discussion-thread-message-remove-bt {
        left: -40px;
        top: 32px;
    }
}

.discussion-thread-message-replies {
    .discussion-avatar {
        height: 48px;
        left: 76px;
        width: 48px;
    }
}

.discussion-thread-message-reply {
    margin-left: -76px;
    padding-left: 136px;

    /* reply line */

    &::before {
        background: @quaternary-color-xxxlight;
        bottom: 0;
        content: "";
        left: 32px;
        position: absolute;
        top: 0;
        width: 2px;
    }

    &:last-child {
        &::before {
            bottom: auto;
            height: 25px;
        }

        &::after {
            border: 1px solid @border-color-light;
            content: "";
            height: 11px;
            left: 27px;
            margin-top: -6px;
            position: absolute;
            top: 35px;
            width: 11px;
            .border-radius(10px, 10px, 10px, 10px);
        }
    }

    > .discussion-thread-message-remove-bt {
        top: 24px;
    }
}

.discussion-thread-username {
    display: inline-block;
    margin: -2px 0 6px;
    vertical-align: top;
}

.discussion-thread-reply-link {
    color: @text-color-dark;
    font-weight: bold;
    margin-right: 12px;
    .type(detail);
}

.discussion-thread-hide-link {
    .discussion-thread-reply-link;
}

.discussion-thread-message-date {
    .type(detail);
    font-weight: bold;

    [class*="fl-icon-"] {
        color: @quaternary-color-xlight;
        display: inline-block;
        margin-top: -1px;
        vertical-align: top;
        .type(heading3);
    }
}

.discussion-thread-message-removed {
    font-weight: bold;
    .type(detail);

    [class*="fl-icon-"] {
        color: @quaternary-color-xlight;
        display: inline-block;
        margin-top: -1px;
        vertical-align: top;
        .type(heading3);
    }
}

.discussion-thread-reveal-bt {
    background: none;
    border: none;
    height: 30px;
    margin: @spacing-small - @spacing-xxsmall 0 0;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;

    &::before {
        background-color: @quaternary-color-xxlight;
        content: "";
        height: 1px;
        left: 0;
        margin-top: -1px;
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: 1;
    }

    &:hover {
        .discussion-thread-reveal-inner {
            .transition(.15s, ease-in);
            padding: 0 25px;
        }
    }
}

.discussion-thread-reveal-inner {
    background-color: @site-bg-color;
    color: @quaternary-color-xxlight;
    padding: 0 15px;
    position: relative;
    z-index: 2;
    .transition(.15s, ease-out);
}

.discussion-thread-vote {
    color: @quaternary-color-dark;
    font-weight: bold;
    padding-left: 10px;

    &::before {
        border-color: transparent transparent @quaternary-color-dark transparent;
        border-style: solid;
        border-width: 0 6px 7px;
        content: "";
        display: inline-block;
        height: 0px;
        margin-right: 5px;
        position: relative;
        top: -1px;
        width: 0px;
        .transition(0.2s, ease-out);
        .animated;
    }

    &:hover::before {
        top: -4px;
    }

    &:active::before {
        .bounce;
    }

    &.is-active {
        color: @quinary-color;

        &::before {
            border-color: transparent transparent @quinary-color transparent;
        }
    }
}

/*********************************************/
/** admin **/

.discussion-thread-message-remove-bt {
    background: transparent;
    background-color: @site-bg-color;
    border: none;
    color: @quaternary-color-xxlight;
    font-size: 30px;
    left: 21px;
    opacity: 0;
    padding: 0;
    position: absolute;
    z-index: 3;
}
