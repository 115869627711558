/**
 * Tables
 *
 * Tables are used to display tabular data.
 *
 * Styleguide: Tables
 */

/**
 * Default Table
 *
 * Used to display tabular data.
 *
 * Markup: default-table.html
 *
 * Weight: -999
 *
 * Styleguide: Tables - Default
 */

/**
 * Table /w header
 *
 * Table with a header
 *
 * Markup: default-table-with-header.html
 *
 * Weight: -998
 *
 * Styleguide: Tables - With Header
 */

/**
 * Table /w filters
 *
 * Table with filters to sort data
 *
 * Markup: default-table-with-filters.html
 *
 * Weight: -997
 *
 * Styleguide: Tables - With Filters
 */

@import (reference) "dropdown.less";
@import "select.less";

@import (reference) "libs/mixins.less";

/* Animations */
@import "mixins/animated/fadeInDown.less";
@import "mixins/animated/fadeInUp.less";

.triangle {
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    content: '';
    -moz-transform: scale(.9999);
}

.clearfix() {
  zoom: 1;
  &:before { content: ''; display: block; }
  &:after { content: ''; display: table; clear: both; }
}

/* Table header 'attaches' to the top of a table and contains search and filter elements */

.table-header {
    position: relative;
    height: 76px;
    padding: 18px 19px 18px 6px;
    margin-left: 15px;
    background-color: @quaternary-color;
    color: @text-color-light;
    &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: -15px;
        width: 15px;
        bottom: 0;
        background-color: @quaternary-color;
    }
    &:after {
        position: absolute;
        top: 0;
        left: -15px;
        .triangle;
        border-width: 0 0 15px 15px;
        border-color: transparent transparent @quaternary-color-light transparent;
    }
}

.table-header-info {
    display: inline-block;
    width: 40%;
    margin-top: 10px;
    .type(heading3);
    vertical-align: top;
}

.table-header-options {
    display: inline-block;
    width: 59%;
    text-align: right;
}

.table-header-bt {
    .transition(.1s, ease-out);
    background-color: @quaternary-color;
    color: @text-color-light;
    border-color: @quaternary-color;
    .border-radius(2px, 2px, 2px, 2px);
    &:before {
        border-color: transparent transparent @text-color-light transparent;
    }
    &:after {
        border-color: @text-color-light transparent transparent transparent;
    }
    &:hover {
        background-color: @quaternary-color-xxxlight;
        border-color: @quaternary-color-light;
        &:before {
            border-color: transparent transparent @text-color-light transparent;
        }
        &:after {
            border-color: @text-color-light transparent transparent transparent;
        }
    }
}


.table-header-filter-toggle {
    .type(ui);
    margin-right: 8px;
}

.table-header-pagination {
    width: auto;
    min-width: 110px;
    margin-right: 8px;
    vertical-align: top;
    font-weight: 700;
    .psuedo-select-options {
        width: auto;
        min-width: 100px;
        font-weight: normal;
    }
}

.table-header-search {
    display: inline-block;
    width: 142px;
    vertical-align: top;
}

.table-header-search-input {
    width: 142px;
    padding: 11px 9px 10px 35px;
    border: none;
    .border-radius(2px, 2px, 2px, 2px);
    background-color: @septenary-color-xxxlight;
    .type(ui);
}

.filter-display-result {
    border: none;
}

/* table filters */

.table-filters {
    .clearfix;
    position: relative;
    z-index: 2;
    display: none;
    border-left: 1px solid @quaternary-color-xxxlight;
    border-right: 1px solid @quaternary-color-xxxlight;
    background-color: @quaternary-color-xxxxxlight;
    .psuedo-select-options {
        font-weight: normal;
    }
}

.table-filter-set {
    .clearfix;
    border-top: 1px solid @quaternary-color-xxxlight;
    .box-shadow(inset 0 1px 0 @quaternary-color-xxxxxlight);
    &:first-child {
        border-top: none;
        .box-shadow(inset 0 0 0 transparent);
    }
}

.table-filter-label {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
    font-weight: bold;
}

.table-filter-range {
    display: inline-block;
    vertical-align: top;
    width: 70%;
    font-weight: bold;
    text-align: right;
    color: @quaternary-color-xlight;
}

.table-filter-select {
    font-weight: 700;
    border-color: @quaternary-color-xxlight;
}

.table-help {
    display: inline-block;
    vertical-align: top;
    width: 16px;
    height: 16px;
    padding-top: 3px;
    margin-left: 8px;
    margin-top: -4px;
    background-color: @quaternary-color-xxlight;
    border-radius: 50%;
    text-align: center;

    &:before {
        content: '?';
        color: @septenary-color-xxxlight;
        .type(detail);
    }
}


/***************************************************************8/
/* datatable overrides */

.dataTables_wrapper {
    position: relative;
    .dataTables_empty {
        padding: 13px !important;
    }
    .dataTables_info,
    .dataTables_paginate {
        display: inline-block;
        width: 50%;
        vertical-align: top;
    }
    .dataTables_info {
        vertical-align: top;
        margin-top: 14px;
    }
    .dataTables_paginate {
        text-align: right;
        ul {
            display: inline-block;
        }
    }
    .dataTables_processing {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
        z-index: 1;
        span {
            position: absolute;
            z-index: 4;
            top: 50%;
            left: 50%;
            margin-top: -19px;
            margin-left: -75px;
            width: 150px;
            height: 38px;
            padding: 10px;
            .border-radius(3px, 3px, 3px, 3px);
            background-color: @quaternary-color-xxxxxlight;
            border: 1px solid @primary-color;
            color: @primary-color;
            text-align: center;
            font-weight: bold;
            .animated(.2s);
            .fadeInDown;
        }
    }

    /* 1. .fg-toolbar needs to be out of page flow so .dataTables_processing doesn't cover it on loading */

    table {
        margin-bottom: 65px; /* 1. */
    }

    .dataTables_length,
    .dataTables_filter {
        display: none;
    }

    .fg-toolbar {
        position: absolute; /* 1. */
        left: 0;
        right: 0;
        top: 100%;

        .pagination {
            margin: 0;
            ul {
                -moz-box-shadow: 0 0 0 transparent;
                -webkit-box-shadow: 0 0 0 transparent;
                box-shadow: 0 0 0 transparent;
            }
        }
    }
}

/***************************************************************8/
/* default table */

.default-table {
    width: 100% !important;
    border: none;
    border-collapse: collapse;
    &.default-table-large {
        thead {
            th {
                padding: 20px 0 20px 24px;
            }
        }
        tbody {
            td {
                padding: 24px;
                vertical-align: middle;
            }
        }
    }
    .is-locked {
        color: @text-color-mid;
    }
    caption {
        text-align: left;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 700;
    }
    &.has-no-side-border {
        tr {
            border-left: none;
            border-right: none;
        }
        &:hover {
            tr {
                border-left: none !important;
                border-right: none !important;
            }
        }
    }
    .table-row-hl {
        .transition(.1s, ease-out);
        background-color: @table-hl-color;
        border-top: 1px solid @table-hl-border-color;
        border-bottom: 1px solid @table-hl-border-color;
    }
    &.table-alt-row {
        tr:nth-child(even) {
            background-color: @quaternary-color-xxxxlight;
        }
    }
    .table-label {
        display: block;
        color: @quaternary-color-light;
        text-transform: capitalize;
    }

    thead {
        border: 1px solid @border-color-light;
        .type(ui);
        text-transform: uppercase;
        color: @text-color-dark;
    }

    td,
    th {
        padding: 13px;
    }
    th {
        position: relative;
        padding-top: 16px;
        padding-bottom: 16px;
        text-align: left;

        &.sorting,
        &.sorting_asc,
        &.sorting_desc {
            cursor: pointer;
            .dark-link-color;
        }
        &.sorting_asc,
        &.sorting_desc {
            span {
                position: relative;
                padding-right: 14px;

                &:after {
                    .animated(.2s);
                    .triangle;
                    position: absolute;
                    top: 5px;
                    right: 0;
                    margin-left: -4px;
                }
            }
        }
        &.sorting_desc {
            span {
                &:after {
                    .fadeInDown;
                    border-width: 4px 4px 0 4px;
                    border-color: @primary-color-light transparent transparent transparent;
                }
            }
        }
        &.sorting_asc {
            span {
                &:after {
                    .fadeInUp;
                    border-width: 0 4px 4px 4px;
                    border-color: transparent transparent @primary-color-light transparent;
                }
            }
        }
    }
    tbody {
        border: none;
        border-bottom: 1px solid @quaternary-color-xxxlight;
        tr {
            border-top: 1px solid @quaternary-color-xxxlight;
            border-left: 1px solid @quaternary-color-xxxlight;
            border-right: 1px solid @quaternary-color-xxxlight;
            .transition(.2s, ease-out);
            &:hover {
                .transition(.2s, ease-out);
                background-color: @hover-bg-color !important;
                border: 1px solid darken(@primary-color-xxxxlight, 20%) !important;
            }
        }
        td {
            vertical-align: top;
        }
        .table-no-value {
            position: relative;
            &:before {
                content: '';
                top: 50%;
                left: 50%;
                position: absolute;
                width: 10px;
                height: 2px;
                margin-left: -5px;
                margin-top: -1px;
                background-color: @quaternary-color-xxlight;
            }
        }
    }
}
