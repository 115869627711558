@import (reference) "libs/bootstrap.3.2.grid.less";
@import "../../flux/modules/code-sample.less";
@import "../../flux/modules/section-title.less";
@import "../modules/help-accordion.less";



   /*******************************************************/
    /*******************************************************/
    /** Sites verify **/

    .sites-verify-inner{
        .site-section-inner;
        padding-top:56px;
        padding-bottom:100px;
    }

    .sites-verify-form{
        .form-step{
            display:inline-block;
            vertical-align:top;
            margin-bottom:4px;
        }
    }

    /*******************************************************/
    /** Sites verify intro **/

    .sites-verify-intro{
        .make-xs-column(12);
        display:table;
        position:relative;
        width:100%;
        background-color:@quinary-color;
        margin-bottom:40px;

        &:before{
            content:'';
            position:absolute;
            right:0;
            top:0;
            bottom:0;
            width:296px;
            background-color:@septenary-color-xxlight;
        }
    }

    .sites-verify-intro-inner{
        display:table-cell;
        padding:22px 30px 30px;
        color:@septenary-color-xxxlight;
    }

    .sites-verify-title{
        font-size:32px;
        margin-bottom:4px;
    }

    .sites-verify-details{
        display:table-cell;
        width:296px;
        padding:0 30px;
        text-align:center;
        vertical-align:middle;
    }

    .sites-verify-details-inner{
        display:inline-block;
        position:relative;
        max-width:210px;
        text-align:left;
        padding-left:42px;
        font-weight:700;
        word-break:break-all;

        [class*="fm-icon-"]{
            position:absolute;
            left:0;
            top:0;
            font-size:30px;
        }
    }

    .sites-verify-details-price{
        position:relative;
        display:block;
        font-size:12px;
    }


    /*******************************************************/
    /** Sites verify steps **/

    .sites-verify-step-name{
        position:relative;
        font-size:24px;

        &.is-active{
            color:@positive-color;
            [class*="fm-icon-"]:after{
                background-color:@positive-color;
            }
        }

        [class*="fm-icon-"]{
            position:absolute;
            display:inline-block;
            width:26px;
            height:26px;
            top:2px;
            left:-33px;
            color:@septenary-color-xxxlight;
            font-size:16px;

            &:before{
                position:absolute;
                z-index:2;
                top:5px;
                left:5px;
            }

            &:after{
                content:'';
                position:absolute;
                left:0;
                top:0;
                width:26px;
                height:26px;
                background-color:@quaternary-color-xxxlight;
                .border-radius(50%, 50%, 50%, 50%);
            }
        }
    }

    .sites-verify-steps{
        .make-xs-column(7);
        padding:0;
        margin:0;
        list-style:none;

        .alert{
            margin-bottom:20px;
        }
    }

    .sites-verify-step{
        position:relative;
        padding:0 0 50px 33px;
        &:first-child{
            &:before{
                content:'';
                position:absolute;
                top:2px;
                bottom:-2px;
                left:12px;
                width:1px;
                background-color:@quaternary-color-xxxlight;
            }
        }
        .alert {
            padding: 10px 15px;
        }
        .sites-verify-owner-status {
            padding: 20px;
            margin-bottom:20px;
            a {
                color: #FFFFFF;
                font-weight: bold;
            }
        }
    }

    .sites-verify-options{
        list-style:none;
    }

    /* 1. For IE */

    .sites-verify-option{
        position:relative;
        padding:0 0 50px 33px;
        margin:0;
        &:before,
        &:after{
            content:'';
            position:absolute;
            background-color:@quaternary-color-xxxlight;
        }
        &:before{
            top:10px;
            bottom:-5px;
            left:12px;
            width:1px;
        }
        &:after{
            content:'or';
            left:2px;
            top:4px;
            width:22px;
            height:18px;
            padding-top:4px;
            line-height:1;
            .border-radius(50%, 50%, 50%, 50%);
            text-align:center;
            font-weight:700;
            color:@text-color-light;
            font-size:12px;
        }
        &:first-child{
            &:after{
                content:'';
            }
        }
        &:last-child{
            &:before{
                display:none;
            }
        }
        .code-sample {
            word-wrap: break-word;
        }
    }

    /* 1. */
    .sites-verify-option:first-child + li + li{
        &:before{
                display:none;
            }
    }

    .sites-verify-option-name{
        font-size:24px;
    }

    .sites-verify-email-input{
        margin-bottom:14px;
    }

    /*******************************************************/
    /** Sites verify ownership **/

    .sites-verify-meta-bt,
    .sites-verify-sms-bt,
    .sites-verify-txt-bt{
        margin-right:10px;
    }

    /*******************************************************/
    /** Sites verify phone number **/

    .sites-verify-step-intro{
        padding-bottom:22px;
        margin-bottom:22px;
        border-bottom:1px solid @quaternary-color-xxxlight;
    }

    .sites-verify-phone-country{
        min-width:146px;
        max-width: 222px;
        margin-right:14px;

        select {
            padding: 9px 0 9px 12px;
        }
    }

    .sites-verify-phone-number{
        width:220px;

        input {
            padding: 0 15px;
        }
    }

    .sites-verify-sms{
        display:inline-block;
        width:50%;
        padding-right:20px;
        vertical-align:top;
    }

    .sites-verify-sms-title{
        display:inline-block;
        width:20%;
        font-weight:700;
        font-size:15px;
    }

    .sites-verify-sms-options{
        display:inline-block;
        width:80%;
        vertical-align:top;
        margin-top:2px;
        text-align:right;
        a{
            padding:0 4px;
            border-left:1px solid @quaternary-color-xxlight;
            &:first-child{
                border-left:none;
            }
        }
    }

    .sites-verify-sms-number{
        display:inline-block;
        margin-top:13px;
        font-size:24px;
    }

    .sites-verify-code-form{
        display:inline-block;
        width:50%;
        padding-left:20px;
        vertical-align:top;
        border-left:1px solid @quaternary-color-xxxlight;
        .form-step{
            margin-bottom:0;
        }
    }

    .sites-verify-enter-code,
    .sites-verify-code-submit{
        display:inline-block;
        width:50%;
    }

    .sites-verify-enter-code{
        padding-right:10px;
    }

    .sites-verify-completed-title{
        display:block;
        font-weight:700;
        font-size:15px;
    }

    .sites-verify-cancel-bt{
        margin:-8px 0 0 20px;
    }

    /*******************************************************/
    /** Sites verify side column **/

    .sites-verify-help{
        .make-xs-column(4);
        .make-xs-column-offset(1);
        .section-title{
            margin-bottom:12px;
        }
    }
