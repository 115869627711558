@import (reference) "popover-link.less";
@import (reference) "dropdown.less";
@import (reference) "bootstrap-custom/bootstrap-custom.less";
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import "mixins/animated/fadeInRight.less";

.notification-popover {
    .custom-popover();
    position: absolute;
    width: 400px;
    background: @septenary-color-xxxlight;

    .popover-head {
        display: table;
        width: 100%;
        height: 48px;
        padding: 0 13px;
        border-bottom: 1px solid @septenary-color-xlight;
        background: @septenary-color-xxxlight;
    }

    .popover-head-title {
        display: table-cell;
        vertical-align: middle;
    }

    .popover-head-title {
        .type(ui);
        font-weight: bold;
        text-transform: uppercase;
    }

    .popover-content {
        position: relative;
        overflow: hidden;

        > .slimScrollDiv,
        .user-notification-thread > .slimScrollDiv,
        .notification-popover-thread {
            height: 379px !important;
            border-radius: 0 0 4px 4px;
        }
    }

    .notification-setting-bt {
        position: absolute;
        top: 13px;
        right: 15px;

        [class*="flicon-"] {
            height: 19px;
            stroke: @septenary-color-light;
        }

        .flicon-gear {
            width: 19px;
        }

        .flicon-chevron-right {
            width: 14px;
        }

        a:hover {
            text-decoration: none;

            .fl-icon-cog {
                color: @quaternary-color-light;
            }
        }
    }

    .notification-dropdown-head {
        .dropdown-heading;
        padding: 18px 25px 13px;
        color: @septenary-color-xxdark;
        background-color: transparent;
    }

    .dropdown {
        .flicon-chevron-right {
            display: none;
        }

        &.open {
            .flicon-gear {
                display: none;
            }

            .flicon-chevron-right {
                display: block;
            }

            .notification-backdrop {
                display: block;
                z-index: 3;
            }
        }
    }

    // Something's overriding the the right keyframes needed so it needs to be declared inside of this LESS file.
    @-webkit-keyframes fadeInRightx {
        0% {
            opacity: 0;
            transform: translateX(20px);
        }

        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes fadeInRightx {
        0% {
            opacity: 0;
            transform: translateX(20px);
        }

        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .fadeInRightx {
        animation-name: fadeInRightx;
    }

    .dropdown-menu {
        .animated(0.25s);
        .fadeInRightx;
        top: 33px;
        right: -14px;
        left: auto;
        width: 300px;
        height: 380px;
        box-shadow: none;

        > li > a {
            padding-left: 25px;
        }

        .saving-status {
            text-align: center;
        }
    }

    .notification-backdrop {
        display: none;
        position: absolute;
        top: 35px;
        right: -14px;
        z-index: 0;
        width: 399px;
        height: 380px;
        background-color: rgba(0, 0, 0, 0.65);
    }

    .notification-setting-checkbox {
        margin-right: 10px;
    }

    .notification-setting-skill {
        position: relative;

        &:hover .Icon {
            fill: @quaternary-color-dark;
        }

        &-icon {
            display: none;
        }

        &-contract,
        &-expand {
            .notification-setting-skill-icon {
                display: flex;
                align-items: flex-end;
            }
        }

        &-contract {
            .notification-setting-skill-icon--subtract {
                display: flex;
            }

            .notification-setting-skill-icon--add {
                display: none;
            }
        }

        &-expand {
            .notification-setting-skill-icon--subtract {
                display: none;
            }

            .notification-setting-skill-icon--add {
                display: flex;
            }
        }
    }

    .notification-setting-skill-bt {
        .type(detail);
        position: absolute;
        top: 2px;
        right: 0;
        padding: 3px 5px;
        background: none;
    }

    .notification-setting-skill-list {
        padding-bottom: 10px;
        list-style-type: none;

        a {
            display: inline-block;
            padding: 3px 0;
            color: @septenary-color-dark;
        }
    }

    .saving-status {
        margin: 3px;
        text-align: right;

        .alert {
            display: block;
            margin: 0;
            padding: 5px;
            text-align: center;
        }
    }

    .notification-popover-thread {
        margin: 0;
        padding: 0;
        list-style-type: none;

        &[data-list="empty"] {
            height: 60px;
        }
    }

    .empty-list {
        height: 60px;
        padding: 20px;
        text-align: center;
    }

    .notification-popover-item {
        position: relative;

        &[data-state="unread"] {
            .notification-item-content {
                background: @quaternary-color-xxxxxlight;

                &:hover {
                    background-color: @bg-color-light;
                }
            }
        }

        .group-chat-avatar {
            position: absolute;
            margin: 12px 13px;

            &-img {
                width: 48px;
                height: 48px;
            }
        }
    }

    .notification-item-read {
        position: absolute;
        top: 9px;
        right: 9px;
        font-size: 10px;
        cursor: pointer;

        [class*="fl-icon-"] {
            color: @quaternary-color-xxlight;
        }
    }

    .profile-img {
        position: absolute;
        z-index: 2;
        width: 48px;
        height: 48px;
        margin: 13px 12px;
        border-radius: 3px;
    }

    .notification-item-content {
        height: 76px;
        padding: 13px 8px 13px 72px;
        border-bottom: 1px solid @septenary-color-xlight;
        cursor: pointer;
        .type(ui);
        .transition(0.1s, ease-out);

        .notification-item-title {
            .type(ui);
            margin-top: -2px;
            margin-bottom: 0;
            line-height: 1.4;
            color: @septenary-color-xxdark;
        }

        .online-status {
            display: inline-block;
            margin-left: 5px;
        }

        &:hover {
            background-color: @bg-color-light;
        }
    }

    .notification-item-text {
        .type(ui);
        width: 245px;
        margin: 0;
        line-height: 1.4;
        color: @septenary-color-xdark;

        strong {
            color: @septenary-color-xxdark;
        }
    }

    .notification-item-status {
        line-height: 1.4;
    }

    .notification-popover-more-bt {
        .popover-more-link;
        .type(ui);
        position: relative;
        z-index: 3;
        margin-top: -1px;
        padding: 14px 13px 17px;
        border-top: 1px solid @septenary-color-xlight;
        color: @septenary-color-xxdark;
    }

    .loading {
        margin: 5px;
        text-align: center;
    }
}
