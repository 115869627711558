@font-face {
    font-family: 'freemarket';
    src:url('/static/css/freemarket/icons/fonts/freemarket.eot');
    src:url('/static/css/freemarket/icons/fonts/freemarket.eot?#iefix') format('embedded-opentype'),
        url('/static/css/freemarket/icons/fonts/freemarket.woff') format('woff'),
        url('/static/css/freemarket/icons/fonts/freemarket.ttf') format('truetype'),
        url('/static/css/freemarket/icons/fonts/freemarket.svg#freemarket') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="fm-icon-"], [class*=" fm-icon-"] {
    font-family: 'freemarket';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fm-icon-time:before {
    content: "\e609";
}
.fm-icon-list-alt:before {
    content: "\e610";
}
.fm-icon-chevron-right:before {
    content: "\e620";
}
.fm-icon-info-sign:before {
    content: "\e629";
}
.fm-icon-question-sign:before {
    content: "\e632";
}
.fm-icon-exclamation-sign:before {
    content: "\e634";
}
.fm-icon-warning-sign:before {
    content: "\e639";
}
.fm-icon-chevron-down:before {
    content: "\e63c";
}
.fm-icon-chevron-up:before {
    content: "\e642";
}
.fm-icon-shield:before {
    content: "\e651";
}
.fm-icon-search:before {
    content: "\e69b";
}
.fm-icon-chevron-left:before {
    content: "\e6b8";
}
.fm-icon-coin:before {
    content: "\e65b";
}
.fm-icon-close:before {
    content: "\e668";
}
.fm-icon-checkmark:before {
    content: "\e669";
}
.fm-icon-checkin2:before {
    content: "\e6a7";
}
.fm-icon-cash:before {
    content: "\e6a9";
}
.fm-icon-trafficlight:before {
    content: "\e6a8";
}
.fm-icon-colocation2:before {
    content: "\e6a4";
}
.fm-icon-comment6:before {
    content: "\e6a6";
}
.fm-icon-settings:before {
    content: "\e60e";
}
.fm-icon-facebook2:before {
    content: "\e67d";
}
.fm-icon-menu:before {
    content: "\e600";
}
.fm-icon-logout:before {
    content: "\e60c";
}
.fm-icon-close-light:before {
    content: "\e60d";
}
.fm-icon-protection:before {
    content: "\e601";
}
.fm-icon-commission:before {
    content: "\e602";
}
.fm-icon-free:before {
    content: "\e603";
}
.fm-icon-highquality:before {
    content: "\e604";
}
.fm-icon-uniE605:before {
    content: "\e605";
}
.fm-icon-buyers:before {
    content: "\e606";
}
.fm-icon-secure-payments:before {
    content: "\e607";
}
.fm-icon-transfers:before {
    content: "\e608";
}
.fm-icon-handover:before {
    content: "\e60a";
}
.fm-icon-website:before {
    content: "\e60b";
}
.fm-icon-bids:before {
    content: "\e60f";
}
.fl-icon-first:before {
    content: "\00ab";
}
.fl-icon-prev:before {
    content: "\2039";
}
.fl-icon-next:before {
    content: "\203a";
}
.fl-icon-last:before {
    content: "\00bb";
}
