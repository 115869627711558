@import (reference) "../../libs/mixins.less";
@import (reference) "../../theme/variables.less";

.help-accordion{
        margin:0;
        padding:0;
        border-top:1px solid @quaternary-color-xxxlight;
    }

    .help-accordion-group {
        margin-bottom:0px;
    }

    .help-accordion-heading {
        border-bottom:0;
    }

    .help-accordion-heading .help-accordion-toggle {
        display:block;
        padding:8px 20px;
    }

    .help-accordion-toggle {
        position:relative;
        color:@text-color-dark;
        padding:8px 20px;
        border-bottom:1px solid @quaternary-color-xxxlight;
        cursor: pointer;
        &:after{
            .transition(.2s, ease-in);
            content:'';
            position:absolute;
            bottom:-1px;
            left:0;
            right:100%;
            height:1px;
            background-color:@link-color;
        }
        &:hover{
            color:@link-color;
            text-decoration:none;
            &:after{
                .transition(.2s, ease-out);
                right:0;
            }
        }
    }

    .help-accordion-inner {
        padding:9px 20px 11px;
    }

    .help-accordion-body {
        &.collapse {
            -webkit-transition: height 0.35s ease;
            -moz-transition: height 0.35s ease;
            -ms-transition: height 0.35s ease;
            -o-transition: height 0.35s ease;
            transition: height 0.35s ease;
            position: relative;
            overflow: hidden;
            height: 0;
            &.in {
                height: auto;
            }
        }
    }
