/* 1. set the gutter width for semantic grid to 0 for just inside the forms */

@import (reference) '../../flux/modules/dropdown.less';
@import '../../flux/modules/select.less';
@import '../../flux/modules/inputs.less';

/* Animations */
@import (reference) "../../libs/mixins.less";
@import "../../mixins/animated/fadeInLeft.less";
@import "../../mixins/animated/fadeInUp.less";
@import "../../mixins/animated/fadeInDown.less";
@import "../../mixins/animated/bounceIn.less";

@gutter-width: 0; /* 1. */

//component for fieldset number
.circled-number{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: px;
    font-weight: bold;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height:30px;
    text-align: center;
    .rounded(50%);
    background: @primary-color-light;
    color:@septenary-color-xxxlight;
}

/*************************************************/
/* field states */

.input-valid-state{
    label,[class*="fl-icon-"]{
        color:@field-valid-color;
    }
    [type="text"],
    [type="password"],
    [type="email"],
    [type="number"],
    textarea,
    select{
        color:@field-valid-color;
        border-color:@field-valid-color;
        &:focus{
            .input-focus-constructor(@field-valid-color);
        }
    }
}

.input-error-state{
    label,[class*="fl-icon-"]{
        color:@field-error-color;
    }
    [type="text"],
    [type="password"],
    [type="email"],
    [type="number"],
    textarea,
    select{
        color:@field-error-color;
        border-color:@field-error-color;
        &:focus{
            .input-focus-constructor(@field-error-color);
        }
    }
    ::-webkit-input-placeholder{
        color: @field-error-color;
    }
    ::-moz-placeholder{
        color: @field-error-color;
    }
    :-ms-input-placeholder {
        color: @field-error-color!important;
    }
}

.input-help-state{
    label,[class*="fl-icon-"]{
        color:@field-help-color;
    }
    [type="text"],
    [type="password"],
    [type="email"],
    [type="number"],
    textarea,
    select{
        color:@field-help-color;
        border-color:@field-help-color;
        &:focus{
            .input-focus-constructor(@field-help-color);
        }
    }
    ::-webkit-input-placeholder{
        color: @field-help-color;
    }
    ::-moz-placeholder{
        color: @field-help-color;
    }
    :-ms-input-placeholder {
        color: @field-help-color!important;
    }
}

/*************************************************/
/* search */

.default-search,
.default-search-small{
    position: relative;
    display:inline-block;
    [class*="fl-icon-"]{
        position: absolute;
        left:9px;
        top: 8px;
        color: @quaternary-color-xxlight;
        font-size: 20px;
    }
    &:hover{
        [class*="fl-icon-"]{
            color: @text-color-mid;
            .transition(.2s, ease-out);
        }
    }
}

.default-search-input{
    .default-input;
    padding-left:35px;
}

/* small */

.default-search-small{
    .default-search-input{
        .small-input;
        padding-left:30px;
    }
    [class*="fl-icon-"]{
        position: absolute;
        left:8px;
        top: 8px;
        color: @quaternary-color-xxlight;
        font-size: 16px;
    }
}

/*************************************************/
/*
    default form - add this class to your form to give it consistent styling
    whether it's large, small, or just default.
    1. has-number is a mutator to give the fieldset number before the legend
    2. always use form-step for wrapping label, form error and the input
    3. select2 needs special assignment on form-step
    4. validation is dictated on form-step, is-valid/ is-invalid mutator classes belong to form-step class
*/

.fl-form{

     &.is-simple-form{
        fieldset{
            margin-bottom:40px;
        }
        legend{
            border-bottom:none;
            margin-bottom:10px;

            [class*="fl-icon-"]{
                margin-right:10px;
            }
        }
        .form-step{
            padding-bottom:16px !important;

            > label:first-child{
                display:none;
            }

            &.has-label{
                label:first-child{
                    display:block;
                }
            }
        }
        ::-ms-input-placeholder{
            color: @quaternary-color-xlight!important;
        }
        ::-webkit-input-placeholder{
            color: @septenary-color;
        }
        ::-moz-placeholder{
            color: @quaternary-color-xlight;
        }
        :-ms-input-placeholder {
            color: @quaternary-color-xlight!important;
        }
    }


    legend{
        font-size: 20px;
        font-weight: 300;
        line-height: 1.2;
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }

    ol{
        padding: 0;
        margin: 0;
        list-style-type:none;
    }

    fieldset{
        position:relative;
        margin: 0 0 50px 0;
        > ol{
            list-style: none;
        }
    }

    &.has-numbers{
        >ol{
            margin: 0;
            padding: 0;
            list-style-type:decimal;
            >li{
                position:relative; // Create a positioning context
                list-style:none; // Disable the normal item numbering
                counter-increment:li; // Increment the counter by 1
                &:before{
                    content:counter(li); //Use the counter as content
                    position:absolute;
                    top: -3px;
                    left: 0px;
                    .circled-number;
                }
                &.is-submit:before{
                    display:none;
                }
                ol{
                    padding: 0;
                    margin: 0;
                    list-style: none;
                }
            }
            legend{
                padding-left: 40px;
                border: none;
            }
            .is-submit,
            .is-accept{
                &:before{
                    display:none;
                }
            }
        }
    }

    /* 1. accept label still still show even for simple forms */

    .is-accept label{
        display:block !important; /* 1. */
    }

    .form-accept{
        position:relative;
        margin-top:5px;
        padding-left:30px;
        font-weight: normal;
        cursor:pointer;
        input[type="checkbox"]{
            position:absolute;
            top:3px;
            left:3px;
        }
    }

    /*  1. inputs fade in in sequence - we shouldn't need to delay more than 9 as we wouldn't be able to show more on the page at once
    */


    .form-step{ //*2
        position: relative;
        padding-bottom: 20px;
        margin-bottom:0 !important;
        .animated(.4s);
        .fadeIn;

        &.has-inline{
            label:not(:first-child){
                display:inline-block;
                margin-right: 30px;
            }
        }

        &:last-child &:not(.is-hidden){
            margin: 0;
        }

        //4
        &.is-valid{
            .input-valid-state;
            .form-valid-icon{
                .animated(.4s);
                .fadeInUp;
                display: inline-block;
            }
            .form-error{
                display: none;
            }
            .input-icon:before{
                color:@field-valid-color;
            }
        }
        &.is-invalid{
            .input-error-state;
            .form-invalid-icon{
                .animated(.4s);
                .fadeInUp;
                display:inline-block;
            }
            .form-error{
                .animated(.4s);
                .fadeInDown;
                display:block;
            }
            .input-icon:before{
                color:@field-error-color;
            }
        }

        &.is-help{
            .form-help{
                .animated(.4s);
                .fadeInDown;
                display:block;
            }
        }
        // 3. for select2 border color

        &.is-valid{
            .select2-container-multi{
                .select2-choices{
                    border: 1px solid @field-valid-color;
                }
            }
        }
        &.is-invalid{
            .select2-container-multi{
                .select2-choices{
                    border: 1px solid @field-error-color;
                    margin-bottom: 0px;
                }
            }
        }
        &.is-focus{
            .select2-container-multi{
                .select2-choices{
                    border: 1px solid @field-focus-color;
                    margin-bottom: 30px;
                }
            }
        }
    }

    .form-step--multiFields {
        display: table;
        width: 100%;
    }

    .form-step-fieldContainer {
        display: table-cell;

        &:not(:first-child) {
            padding-left: 10px;
        }
    }

    /* 1. Don't bold checkbox/radio labels etc */

    label{
        display: block;
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 1;

        & + label{ /* 1. */
            font-weight: normal;
        }

        &.btn{
            display: inline-block;
        }
    }
    [type="text"],
    [type="email"],
    [type="password"],
    [type="number"]{
        .default-input;
    }
    textarea{
        .default-textarea;
    }
    .checkbox{
        font-weight: normal;
    }

    select{
        .default-select;
    }

    [type="checkbox"], [type="radio"]{
        margin-top: 1px;
        margin-right: 10px;
        vertical-align: top;
    }

    .input-group{
        position: relative;
        display: table !important;
        border-collapse: separate;
        width: 100%;

        input,
        select{
            display: table-cell;
            -webkit-border-top-right-radius: 0;
            -webkit-border-bottom-right-radius: 0;
            -moz-border-radius-topright: 0;
            -moz-border-radius-bottomright: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            & + .add-on {
                border-left: none;
                -webkit-border-top-right-radius: @form-field-border-radius;
                -webkit-border-bottom-right-radius: @form-field-border-radius;
                -moz-border-radius-topright: @form-field-border-radius;
                -moz-border-radius-bottomright: @form-field-border-radius;
                border-top-right-radius: @form-field-border-radius;
                border-bottom-right-radius: @form-field-border-radius;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }

        .add-on{
            padding: 9px 12px;
            line-height: 1;
            color: @septenary-color;
            text-align: center;
            background-color: @septenary-color-xxlight;
            border: 1px solid @field-border-color;
            display: table-cell;
            vertical-align: middle;
            -webkit-border-bottom-left-radius: @form-field-border-radius;
            -webkit-border-top-left-radius: @form-field-border-radius;
            -moz-border-radius-bottomleft: @form-field-border-radius;
            -moz-border-radius-topleft: @form-field-border-radius;
            border-bottom-left-radius: @form-field-border-radius;
            border-top-left-radius: @form-field-border-radius;

            &:first-child{
                border-right:0;
            }

            & + input,
            & + select {
                -webkit-border-top-right-radius: @form-field-border-radius;
                -webkit-border-bottom-right-radius: @form-field-border-radius;
                -moz-border-radius-topright: @form-field-border-radius;
                -moz-border-radius-bottomright: @form-field-border-radius;
                border-top-right-radius: @form-field-border-radius;
                border-bottom-right-radius: @form-field-border-radius;
                -webkit-border-bottom-left-radius: 0;
                -webkit-border-top-left-radius: 0;
                -moz-border-radius-bottomleft: 0;
                -moz-border-radius-topleft: 0;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;

            }
        }
    }

//file uploader area
    .file-input-area{
        position: relative;
        background-color: @septenary-color-xxlight;
        padding: 15px;
        .rounded(4px);
        border: 3px dashed @septenary-color-xlight;
        cursor: pointer;
        input{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            .opacity(0);
        }
        .file-input-btn{
            margin-right: 10px;
        }
    }

    /* form icons */

    .form-valid-icon,
    .form-invalid-icon{
        display: none;
        vertical-align: top;
        margin-left: 5px;
    }

    .form-invalid-icon{
        font-size: 13px;
    }

    /* form help */

    .form-help{
        position: relative;
        display: none;
        min-height:20px;
        line-height:16px;
        margin-top: 10px;
        padding-top:1px;
        padding-left: 37px;
        font-style: italic;
        color: @primary-color;
        &:before{
            content: '?';
            position: absolute;
            left: 9px;
            top: -1px;
            height: 20px;
            width:20px;
            background: @primary-color;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            text-align: center;
            color: @text-color-light;
            font-weight: bold;
            font-style: normal;
            font-size:15px;
            line-height:20px;
        }
    }

    /* form input help */

    .form-success{
        display: none;
        margin:5px 0;
        color: @field-valid-color;
    }

    .form-warning {
        display: none;
        margin:5px 0;
        color: @field-warning-color;
    }

    .form-error{
        display: none;
        margin:5px 0;
        color:@field-error-color;
    }

    .form-conditions{
        display:block;
        margin-bottom:20px;
    }
    // used on feedback result of form
    .form-feedback-success{
        background-color: @success-notify-color-light;
        border: 1px solid @success-notify-color;
        color: @success-notify-content-color;
    }
    .form-feedback-error{
        background-color: @error-notify-color-light;
        border: 1px solid @error-notify-color;
        color: @error-notify-content-color;
    }
    .form-feedback-error, .form-feedback-success{
        padding: 10px;
        font-weight: bold;
        margin-bottom: 10px;
        .rounded(3px);
    }
    /* form fieldset help */

    &.has-fieldset-help{
        border-right:6px solid @quaternary-color-xxxlight;
        padding-right:50px;
        fieldset{
            position:relative;
            &:hover{
                &:after{
                    .animated(.3s);
                    .bounceIn;
                    content:'';
                    position:absolute;
                    top:0;
                    right:-73px;
                    width:25px;
                    height:25px;
                    background-color:@quaternary-color-xxxlight;
                    .border-radius(19px, 19px, 19px, 19px);
                    border:7px solid @site-bg-color;
                }

                .form-fieldset-help{
                    .transition(.2s, ease-out);
                    .transition-delay(.2s);
                    opacity:1;
                    left:100%;
                }
            }
        }

        .form-fieldset-help{
            opacity:0;
            position:absolute;
            top:0;
            left:98%;
            z-index:4;
            margin-left:98px;
        }
    }
}


.small-form{
    font-size: 12px;
    [type="text"],
    [type="email"],
    [type="password"],
    [type="number"]{
        .small-input;
    }
    select{
        .small-select;
    }
    textarea{
        .small-textarea;
    }
    [type="checkbox"], [type="radio"]{
        margin-top: -1px;
    }
    legend{
        font-size:18px;
        margin-bottom:16px;
    }
    .form-step{
        .form-invalid-icon{
            margin-top:1px;
            font-size:10px;
        }
        .form-valid-icon{
            font-size:11px;
        }
        .input-icon{
            &:before{
                font-size:17px;
            }
            input{
                padding-left: 29px;
            }
        }
        .form-help{
            margin-top:7px;
        }
    }
    &.has-numbers{
        > ol > li{
            &:before{
                width:26px;
                height:26px;
                top:-2px;
                font-size:17px;
            }
            legend{
                padding-left:33px !important;
            }
        }
    }
}

.large-form{
    font-size: 16px;
    [type="text"],
    [type="email"],
    [type="password"],
    [type="number"]{
        .large-input;
    }
    [type="checkbox"],
    [type="radio"]{
        margin-top:2px;
    }
    select{
        .large-select;
    }
    textarea{
        .large-textarea;
    }
    .form-step{
        padding-bottom:20px;
        .form-invalid-icon{
            margin-top:1px;
            font-size:12px;
        }
        .form-valid-icon{
            font-size:15px;
        }
        .input-icon:before{
            font-size:22px;
        }
    }
    .add-on{
        font-size: 16px;
    }
    .form-help{
        padding-top:0;
    }
    &.has-numbers{
        > ol > li{
            &:before{
                line-height: 29px;
                width:30px;
                height:30px;
                top:-2px;
                font-size:15px;
                font-weight: 900;
            }
            legend{
                margin-bottom:22px;
                font-size:22px;
            }
        }
    }

}
